import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CompactPicker } from 'react-color'
import { useTranslation } from 'react-i18next'

import Modal from 'react-bootstrap/Modal'
import UploadImage from '../Commons/UploadImage'

import {
  createProject,
  updateProject
} from '../../redux/slices/projects'

export const ProjectInputDialog = ({ project, children, onCancel }) => {
  const { t } = useTranslation()
  return (
    <div>
      {children}
      <Modal
        size='sm'
        show={project}
        onHide={onCancel}
        backdrop='static'
      >
        <Modal.Header>
          {project && (
            project.uuid
              ? t('projects.form.header.edit')
              : t('projects.form.header.create')
          )}
        </Modal.Header>
        <Modal.Body>
          {project && <ProjectInput project={project} onCancel={onCancel} />}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export const ProjectInput = ({ project, onCancel }) => {
  const { t } = useTranslation()
  const [editedProject, setEditedProject] = useState(project)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setEditedProject(null)
    }
  }, [])

  async function onSubmit (e) {
    e.preventDefault()
    if (project.uuid) {
      await dispatch(updateProject(editedProject))
    } else {
      await dispatch(createProject(editedProject))
    }
    onCancel()
  }

  function handleChange (key, value) {
    setEditedProject({ ...editedProject, [key]: value })
  }

  return (
    <form className='input-form' onSubmit={onSubmit}>
      {/** Name of project */}
      <input
        placeholder={t('projects.form.input.name')}
        value={editedProject.name}
        onChange={e => handleChange('name', e.target.value)}
      />

      {/** Description of project */}
      <input
        placeholder={t('projects.form.input.description')}
        value={editedProject.description}
        onChange={e => handleChange('description', e.target.value)}
      />

      {/** Color of project */}
      <CompactPicker
        color={editedProject.color}
        onChangeComplete={color => handleChange('color', color.hex)}
      />

      {/** Image of project */}
      <UploadImage
        image={editedProject.image}
        onChange={(img) => handleChange('image', img)}
      />

      {/** Buttons */}
      <div className='input-form-buttons'>
        <button className='warn' type='button' onClick={onCancel}>
          <i className='fa fa-close' /> {t('projects.form.button.cancel')}
        </button>
        <button className='ok' type='submit'>
          {editedProject.uuid
            ? <div><i className='fa fa-save' /> {t('projects.form.button.save')}</div>
            : <div><i className='fa fa-plus' /> {t('projects.form.button.create')}</div>}
        </button>
      </div>
    </form>
  )
}
