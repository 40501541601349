import client from './client'

const route = '/links'

export default {
  get: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'get'
    })
    return response.data
  },
  getNeighbors: async (uuid) => {
    const response = await client({
      url: `${route}/neighbors/${uuid}`,
      method: 'get'
    })
    return response.data
  },
  list: async (filter) => {
    const qs = new URLSearchParams(filter)
    const response = await client({
      url: qs.toString() ? `${route}?${qs.toString()}` : route,
      method: 'get'
    })
    return response.data
  },
  create: async (link) => {
    const response = await client({
      url: route,
      method: 'post',
      data: link
    })
    return response.data
  },
  uploadData: async (data) => {
    try {
      const response = await client({
        url: `${route}/import`,
        method: 'post',
        data
      })
      return response.data
    } catch (err) {
      return Promise.reject(err.response.data.message)
    }
  },
  update: async ({ uuid, ...link }) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'put',
      data: link
    })
    return response.data
  },
  remove: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'delete'
    })
    return response.data.uuid
  }
}
