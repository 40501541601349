import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Visualizations
import TimeGraph from '../Visualization/TimeGraph/TimeGraph'
import Map from '../Visualization/Map/Map'

const ThemeDashboard = () => {
  const maxHeight = window.innerHeight - 64
  const maxWidth = window.innerWidth

  return (
    <Container className='theme-dashboard' fluid>
      <Row style={{ padding: 0, height: maxHeight }} fluid='xl'>
        <Col style={{ padding: 0 }} xl='6'>
          <Map
            height={maxHeight - 2}
            width={maxWidth / 2}
          />
        </Col>
        <Col style={{ padding: 0 }} xl='6'>
          <TimeGraph
            height={maxHeight - 7}
            width={maxWidth / 2}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default ThemeDashboard
