import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import * as L from 'leaflet'
import { FeatureGroup, TileLayer, MapContainer } from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'

import { nodeColors } from '../Visualization/colors'

import {
  updateActor,
  updateActorBatch
} from '../../redux/slices/actors'

const getProperties = (a) => {
  const { geometry, ...actor } = a
  return actor
}

const pointToLayer = (feature, latlng) =>
  L.circleMarker(latlng, ({
    color: nodeColors.Actor[feature.properties.type],
    weight: 5,
    opacity: 0.8
  }))

const onEachFeature = (feature, layer) => {
  if (feature.properties && feature.properties.name) {
    const text = `
      <h4>${feature.properties.name}</h4>
      <hr />
      <span>tipo: ${feature.properties.type === 'human' ? 'Humano' : 'Não Humano'}</span><br/>
      <span>descrição: ${feature.properties.description}</span><br/>
      ${feature.properties.image ? '<img width="100px" src="' + feature.properties.image + '"/>' : ''}
    `
    layer.bindPopup(text)
  }
}
// TODO: some how move map to a separate file.

const ActorSelect = ({
  actors
}) => {
  const drawRef = useRef()
  const [mapIsReady, setMapIsReady] = useState(false)
  const [actor, setActor] = useState()
  const [geojson, setGeoJSON] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (actors.length && drawRef.current && drawRef.current.getLayers().length === 0) {
      const g = actors.filter(a => !!a.geometry).map((a) => ({
        ...JSON.parse(a.geometry),
        properties: getProperties(a)
      }))
      L.geoJSON(g, { onEachFeature, pointToLayer })
        .eachLayer(l => {
          drawRef.current.addLayer(l)
        })
    }
  }, [actors, mapIsReady])

  const setMap = (map) => {
    const resizeObserver = new ResizeObserver(() => {
      if (drawRef.current) {
        map.target.invalidateSize()
      }
    })
    const container = document.getElementById('map-container')
    resizeObserver.observe(container)

    setMapIsReady(true)
  }

  const handleChange = (a) => {
    setActor(a)
  }
  const handleSubmitActorGeometry = async (e) => {
    e.preventDefault()
    if (actor) {
      const a = actors.find(i => i.uuid === actor)
      await dispatch(updateActor({ uuid: actor, geometry: JSON.stringify(geojson) }))
      const g = {
        type: 'FeatureCollection',
        features: [{
          ...geojson,
          properties: getProperties(a)
        }]
      }
      // turn geometry editable
      L.geoJSON(g, { onEachFeature, pointToLayer })
        .eachLayer(l => {
          drawRef.current.addLayer(l)
        })
      handleCancelSetUserGeometry()
    }
  }

  // remove geometry layer to add when it is sent with author
  const handleGeometryCreated = (g) => {
    setGeoJSON(g.layer.toGeoJSON())
    drawRef.current.removeLayer(g.layer)
  }

  // TODO: create an batch update for all actors changed
  const handleGeometryEdited = async ({ layers }) => {
    const data = []
    layers.eachLayer(l => {
      const { geometry, type, properties } = l.toGeoJSON()
      data.push({
        uuid: properties.uuid,
        geometry: JSON.stringify({
          type,
          geometry,
          properties: {}
        })
      })
    })
    await dispatch(updateActorBatch(data))
  }

  // TODO: add
  const handleGeometryDeleted = async ({ layers }) => {
    const data = []
    layers.eachLayer(l => {
      const { properties } = l.toGeoJSON()
      data.push({
        uuid: properties.uuid,
        geometry: null
      })
    })
    await dispatch(updateActorBatch(data))
  }

  const handleCancelSetUserGeometry = () => {
    setGeoJSON()
    setActor()
  }
  return (
    <div>
      <Modal
        size='md'
        show={geojson}
        onHide={handleCancelSetUserGeometry}
        backdrop='static'
      >
        <Modal.Header>
          Selecionar Ator
        </Modal.Header>
        <Modal.Body>
          <Form className='input-form' onSubmit={handleSubmitActorGeometry}>
            <Form.Group controlId='actor'>
              <Form.Label>Tipo de Ator&nbsp;</Form.Label>
              <Form.Select
                aria-label='Selecionar Ator'
                value={actor}
                onChange={(e) => handleChange(e.target.value)}
              >
                <option key='none'>Selecione um ator</option>
                {actors.map((a) => (
                  <option key={a.uuid} value={a.uuid}>{a.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <div className='input-form-buttons'>
                <button className='warn' type='button' onClick={handleCancelSetUserGeometry}>
                  <i className='fa fa-close' /> Cancelar
                </button>
                <button className='ok' type='submit' onClick={handleSubmitActorGeometry}>
                  <i className='fa fa-save' /> Salvar
                </button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
      <MapContainer
        id='map-container'
        style={{ height: window.innerHeight - 200 }}
        center={{ lat: -19.925373, lon: -43.965311 }}
        length={4}
        zoom={11}
        whenReady={setMap}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        <FeatureGroup ref={drawRef}>
          <EditControl
            position='topright'
            onEdited={handleGeometryEdited}
            onCreated={handleGeometryCreated}
            onDeleted={handleGeometryDeleted}
            draw={{
              rectangle: false,
              circle: false,
              circlemarker: false
            }}
          />
        </FeatureGroup>
      </MapContainer>
    </div>
  )
}

export default ActorSelect
