import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'

import joi from 'joi'

import { links } from '../../lib/api'

export const linkSchema = joi.object({
  'source.label': joi.string().required(),
  'source.name': joi.string().required(),
  'target.label': joi.string().required(),
  'target.name': joi.string().required(),
  name: joi.string().required()
})

export const linkHeader = [
  'source.label',
  'source.name',
  'target.label',
  'target.name',
  'name'
]

/**
 * adapters
 */
export const linksAdapter = createEntityAdapter({
  selectId: (i) => i.uuid
})

/**
 * api calls and async functions
 */
export const loadLink = createAsyncThunk(
  'links/loadOne',
  (uuid) => links.get(uuid)
)

export const loadNeighbors = createAsyncThunk(
  'links/loadNeighbors',
  (uuid) => links.getNeighbors(uuid)
)

export const loadAllLinks = createAsyncThunk(
  'links/loadAll',
  async (theme) => links.list(theme)
)

export const createLink = createAsyncThunk(
  'links/create',
  (obj) => links.create(obj)
)

export const uploadLinkData = createAsyncThunk(
  'links/uploadData',
  (obj) => links.uploadData(obj)
)

export const updateLink = createAsyncThunk(
  'links/update',
  async (obj) => {
    const { uuid, ...changes } = await links.update(obj)
    return { id: uuid, changes }
  }
)

export const removeLink = createAsyncThunk(
  'links/remove',
  (uuid) => links.remove(uuid)
)

// TODO: create & update

/**
 * create slices
 */
const linkSlices = createSlice({
  name: 'links',
  initialState: linksAdapter.getInitialState(),
  reducers: {
    clearAll: (state) => {
      state = linksAdapter.getInitialState()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadLink.fulfilled, linksAdapter.upsertOne)
    builder
      .addCase(loadNeighbors.fulfilled, linksAdapter.setAll)
    builder
      .addCase(loadAllLinks.fulfilled, linksAdapter.setAll)
    builder
      .addCase(createLink.fulfilled, linksAdapter.addOne)
    builder
      .addCase(uploadLinkData.fulfilled, linksAdapter.addMany)
    builder
      .addCase(updateLink.fulfilled, linksAdapter.updateOne)
    builder
      .addCase(removeLink.fulfilled, linksAdapter.removeOne)
  }
})

/**
 * export selectors
 */

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectById
} = linksAdapter.getSelectors((state) => state.links)

export const selectAllNodes = createSelector(
  [
    state => selectAll(state).map(l => l.source),
    state => selectAll(state).map(l => l.target)
  ],
  (sources, targets) => Array.from(new Set([...sources, ...targets]))
)

export const selectAllNeighbors = createSelector(
  [(state, uuid) => selectAll(state)
    .filter(l => l.source.uuid === uuid || l.target.uuid === uuid)],
  (links) => {
    const sources = links.map(l => l.source)
    const targets = links.map(l => l.target)
    const nodes = [...sources, ...targets]

    return {
      links: links.map(l => ({ source: l.source.uuid, target: l.target.uuid })),
      nodes: [
        ...new Map(
          nodes.map(item => [item.uuid, item])
        ).values()
      ]
    }
  }
)

/**
 * export reducers
 */
export default linkSlices.reducer

/**
 * export actions
 */
export const { clearAll: clearAllLinks } = linkSlices.actions
