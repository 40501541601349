import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import * as L from 'leaflet'
import { MapContainer, LayersControl, TileLayer, FeatureGroup } from 'react-leaflet'
import BaseGeoJSON from './BaseGeoJSON'
// import EditMap from './EditMap'
// import FeatureInputDialog from '../../Layers/FeatureInputDialog'

import {
  selectBaseLayers
} from '../../../redux/slices/layers'

import {
  selectAll as selectAllActors
} from '../../../redux/slices/actors'

import { nodeColors } from '../colors'

const transformGeoJSON = (a) => ({
  ...JSON.parse(a.geometry),
  properties: {
    name: a.name,
    description: a.description,
    type: a.type,
    image: a.image
  }
})

const pointToLayer = (feature, latlng) =>
  L.circleMarker(latlng, ({
    color: nodeColors.Actor[feature.properties.type],
    weight: 5,
    opacity: 0.8
  }))

const onEachFeature = (feature, layer) => {
  if (feature.properties && feature.properties.name) {
    const text = `
      <h4>${feature.properties.name}</h4>
      <hr />
      <span>tipo: ${feature.properties.type === 'human' ? 'Humano' : 'Não Humano'}</span><br/>
      <span>descrição: ${feature.properties.description}</span><br/>
      ${feature.properties.image ? '<img width="100px" src="' + feature.properties.image + '"/>' : ''}
    `
    layer.bindPopup(text)
  }
}

const style = (feature) => ({
  color: nodeColors.Actor[feature.properties.type]
})

const CustomMap = ({ width, height, handleClick }) => {
  const { t } = useTranslation()
  const [mapIsReady, setMapIsReady] = useState(false)
  const humanRef = useRef(null)
  const notHumanRef = useRef(null)

  /**
   * redux data
   */
  const baseLayers = useSelector(selectBaseLayers)
  const actors = useSelector(selectAllActors)

  useEffect(() => {
    const humanLayer = actors.filter(a => a.geometry && a.type === 'human').map(transformGeoJSON)
    const notHumanLayer = actors.filter(a => a.geometry && a.type === 'not_human').map(transformGeoJSON)
    if (humanLayer.length && humanRef.current && humanRef.current.getLayers().length === 0) {
      L.geoJSON(humanLayer, { onEachFeature, pointToLayer })
        .eachLayer(l => {
          humanRef.current.addLayer(l)
        })
    }

    if (notHumanLayer.length && notHumanRef.current && notHumanRef.current.getLayers().length === 0) {
      L.geoJSON(notHumanLayer, { onEachFeature, pointToLayer, style })
        .eachLayer(l => {
          notHumanRef.current.addLayer(l)
        })
    }
  }, [actors, mapIsReady])

  return (
    <MapContainer
      style={{ height }}
      center={{ lat: -19.925373, lon: -43.965311 }}
      length={4}
      onClick={handleClick}
      zoom={11}
      whenReady={() => setMapIsReady(true)}
    >
      <LayersControl position='topright'>
        <LayersControl.BaseLayer checked name='OSM'>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
        </LayersControl.BaseLayer>
        {baseLayers.filter(l => l.url !== null).map(l =>
          <LayersControl.Overlay key={l.uuid} name={l.name}>
            <BaseGeoJSON layer={l} />
          </LayersControl.Overlay>
        )}
        <LayersControl.Overlay checked name={t('map.layers.human')}>
          <FeatureGroup ref={humanRef} />
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name={t('map.layers.nonHuman')}>
          <FeatureGroup ref={notHumanRef} />
        </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
  )
}

export default CustomMap
