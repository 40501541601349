import React, { useState } from 'react'

const UploadImage = ({ image, square, minHeight, onChange }) => {
  const [preview, setPreview] = useState(image)

  const changeFile = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    const reader = new window.FileReader()

    onChange(file)

    reader.onloadend = () => {
      setPreview(reader.result)
    }

    if (file) {
      // reads the data as a URL
      reader.readAsDataURL(file)
    } else {
      setPreview(null)
    }
  }

  const removeFile = () => {
    onChange(null)
    setPreview(null)
  }

  return (
    <div className='upload-btn-wrapper' style={{ height: square && '100%', minHeight, display: 'flex' }}>
      {preview
        ? (
          <div className='image-wrapper' style={{ backgroundImage: `url(${preview})`, height: square && '100%', flex: '1' }}>
            <div className='del' onClick={removeFile}><i className='fa fa-trash' /></div>
          </div>)
        : (
          <div style={{ height: square && '100%', flex: '1' }}>
            <label htmlFor='file-input' style={{ height: square && '100%', minHeight }}>
              <i className='fa fa-picture-o' />
            </label>
            <input id='file-input' type='file' onChange={changeFile} />
          </div>)}
    </div>
  )
}

export default UploadImage
