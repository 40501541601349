import { replaceImage, queryString } from '../methods'
import client from './client'

const route = '/projects'

export default {
  get: async (uuid) => client({
    url: `${route}/${uuid}`,
    method: 'get'
  }).then(res => res.data),
  list: (filters = {}) => {
    const qs = queryString(filters)
    return client({
      url: `${route}?${qs.toString()}`,
      method: 'get'
    }).then(res => res.data.map(replaceImage))
  },
  create: async ({ image, ...project }) => {
    const form = new FormData()
    for (const key in project) {
      form.append(key, project[key])
    }
    if (typeof image === 'object') {
      form.append('image', image)
    }
    const response = await client({
      url: route,
      method: 'post',
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return replaceImage(response.data)
  },
  update: async ({ image, ...project }) => {
    const form = new FormData()
    console.log(project)
    for (const key in project) {
      if (key !== 'uuid') {
        form.append(key, project[key])
      }
    }

    if (typeof image === 'object') {
      form.append('image', image)
    }

    const response = await client({
      url: `${route}/${project.uuid}`,
      method: 'put',
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return replaceImage(response.data)
  },
  remove: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'delete'
    })
    return response.data.uuid
  }
}
