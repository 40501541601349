import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'
/**
 * api for acessing db
 */
import { layers } from '../../lib/api'

/**
 * for d3 selectors
 */
import { scaleLinear } from 'd3-scale'
import { extent } from 'd3-array'

/**
 * adapters
 */
export const layersAdapter = createEntityAdapter({
  selectId: (i) => i.uuid
  // sortComparer: (a, b) => a.start.localeCompare(b.start)
})

/**
 * api calls and async functions
 */
export const loadOne = createAsyncThunk(
  'layers/loadOne',
  (uuid) => layers.get(uuid)
)

export const loadGeoJSON = createAsyncThunk(
  'layers/loadGeoJSON',
  async (layer) => {
    const geojson = await layers.getGeoJSON(layer.url)
    return { ...layer, geojson }
  }
)

export const loadAllLayers = createAsyncThunk(
  'layers/loadAll',
  (theme) => layers.list(theme)
)

/**
 * create slices
 */
export const layerSlices = createSlice({
  name: 'layers',
  initialState: layersAdapter.getInitialState(),
  reducers: {
    clearAll: (state) => {
      state = layersAdapter.getInitialState()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadGeoJSON.fulfilled, layersAdapter.upsertOne)
    builder
      .addCase(loadOne.fulfilled, layersAdapter.upsertOne)
    builder
      .addCase(loadAllLayers.fulfilled, layersAdapter.setAll)
  }
})

/**
 * export selectors
 */
const selectLayers = (state) => state.layers
export const {
  selectIds: selectLayersIds,
  selectEntities: selectLayersEntities,
  selectAll: selectAllLayers,
  selectById: selectByIdLayer
} = layersAdapter.getSelectors(selectLayers)

export const selectLayersPosistions = ({ width, y }) =>
  createSelector(selectAllLayers, (items) => {
    const degreeExtent = extent(items, n => n.degree || 1)
    const r = scaleLinear(degreeExtent, [6, 12])
    const x = scaleLinear([0, items.length], [r(degreeExtent[0]), width])

    return items.map((i, idx) => ({ ...i, x: x(idx), y, r: r(i.degree || 1) }))
  })

export const selectBaseLayers = createSelector(selectAllLayers, (items) =>
  items.filter(i => i.base)
)

export const selectDrawingLayers = createSelector(selectAllLayers, (items) =>
  items.filter(i => !i.base)
)

/**
 * export reducers
 */
export default layerSlices.reducer

/**
 * export actions
 */
export const { clearAll: clearAllLayers } = layerSlices.actions
