import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {
  login,
  selectAuth
} from '../../redux/slices/auth'

const emptyUser = { username: '', password: '' }

const Login = () => {
  const { t } = useTranslation()
  const [credentials, setCredentials] = useState(emptyUser)
  const dispatch = useDispatch()
  const auth = useSelector(selectAuth)

  const onLogin = async (e) => {
    e.preventDefault()
    await dispatch(login(credentials))
  }

  const onChange = (key, value) => {
    setCredentials({ ...credentials, [key]: value })
  }

  return (
    <div className='login'>
      <form className='column' onSubmit={onLogin}>
        <Container>
          <Row>
            <h3>{t('login.title')}</h3>
          </Row>
          <Row>
            <input
              name='username'
              placeholder={t('login.username')}
              onChange={(e) => onChange('username', e.target.value)}
            />
            <input
              name='password'
              type='password'
              placeholder={t('login.password')}
              onChange={(e) => onChange('password', e.target.value)}
            />
          </Row>
          <Row className='justify-content-end'>
            <Col lg='auto' style={{ padding: 0 }}>
              <button
                type='submit'
                disabled={!credentials.username.length || !credentials.password.length}
              >
                {t('login.login')}
              </button>
            </Col>
            <Col lg='auto' style={{ padding: 0 }}>
              <button type='reset'>{t('login.cancel')}</button>
            </Col>
          </Row>
        </Container>
      </form>
      {auth.error && <div>{auth.error}</div>}
    </div>
  )
}

export default Login
