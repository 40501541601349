/**
 * Router.js
 * - All routes are assigned to respective component pages in this file by using
 *   createBrowserRouter from react-router-dom.
 * - Exports the Router object generated by createBrowserRouter funcion
 */

import React from 'react'
import {
  createBrowserRouter
} from 'react-router-dom'

import LandingPage from './LandingPage/LandingPage'
import { AllProjectsPage } from './Projects/AllProjectsPage'
import ProjectPage from './Projects/ProjectPage'

/**
 * Theme Manager
 */
import ThemePage from './Themes/ThemePage'
import ThemeDashboard from './Themes/ThemeDashboard'
import EventList from './Events/EventList'
import NarrativeList from './Narratives/NarrativeList'
import ActorList from './Actors/ActorList'
import LinkList from './Links/LinkList'
import { Graph } from './Visualization/Graph/Graph'

import { Association } from './Visualization/Association/Association'

import { BASE_URL } from '../constants'

const Router = createBrowserRouter([
  { path: '/', exact: true, element: <LandingPage /> },
  { path: '/home', exact: true, element: <AllProjectsPage /> },
  { path: '/:projectUUID', element: <ProjectPage /> },
  {
    path: '/:projectUUID/:themeUUID',
    element: <ThemePage />,
    children: [{
      path: 'dashboard',
      element: <ThemeDashboard />
    }, {
      path: 'events',
      element: <EventList />
    }, {
      path: 'narratives',
      element: <NarrativeList />
    }, {
      path: 'actors',
      element: <ActorList />
    }, {
      path: 'links',
      element: <LinkList />
    }, {
      path: 'association',
      element: <Association />
    }, {
      path: ':nodeId',
      element: <Graph />
    }]
  }
], {
  basename: BASE_URL
})

export default Router
