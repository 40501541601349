import client from './client'

const route = '/narratives'

export default {
  get: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'get'
    })
    return response.data
  },
  list: async (filter) => {
    const qs = filter && Object.keys(filter).map(k => `${k}=${filter[k]}`).join('&')
    const response = await client({
      url: `${route}${qs && qs.length ? `?${qs}` : ''}`,
      method: 'get'
    })

    return response.data
  },
  create: async ({ theme, narrative }) => {
    const form = new FormData()
    for (const key in narrative) {
      form.append(key, narrative[key])
    }
    form.append('theme', theme)

    const response = await client({
      url: route,
      method: 'post',
      data: form
    })
    return response.data
  },
  uploadData: async (data) => {
    try {
      const response = await client({
        url: `${route}/import`,
        method: 'post',
        data
      })
      return response.data
    } catch (err) {
      return Promise.reject(err.response.data.message)
    }
  },
  update: async ({ image, ...narrative }) => {
    const form = new FormData()
    for (const key in narrative) {
      if (key !== 'uuid') {
        form.append(key, narrative[key])
      }
    }

    const response = await client({
      url: `${route}/${narrative.uuid}`,
      method: 'put',
      data: form
    })
    return response.data
  },
  remove: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'delete'
    })
    return response.data.uuid
  }
}
