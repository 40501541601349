import axios from 'axios'

import { API_URL } from '../../constants'
import { getToken } from '../authentication'
// import { dispatch } from '../../redux/store'
// import { logout } from '../../redux/slices/auth'

const client = axios.create({
  baseURL: API_URL
})

client.interceptors.request.use(async config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

client.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 401 && error.config.url !== '/login') {
    // dispatch(logout())
  } else {
    return Promise.reject(error)
  }
})

export default client
