import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Filter = ({ filter, onChange }) => {
  const { t } = useTranslation()
  return (
    <input
      type='text'
      id='filter'
      placeholder={t('commons.filter.placeholder')}
      style={{ width: 'inherit' }}
      value={filter}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

Filter.propTypes = {
  filter: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default Filter
