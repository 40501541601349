import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { useParams } from 'react-router-dom'

import ListItem from '../Commons/ListItem'
import { ThemeInputDialog } from './ThemeInput'

import {
  removeTheme
} from '../../redux/slices/themes'

const ThemeList = ({ themes }) => {
  const { projectUUID } = useParams('projectUUID')

  const [theme, setTheme] = useState()
  const dispatch = useDispatch()

  const handleEdit = (theme) => {
    setTheme(theme)
  }

  const handleCancel = () => {
    setTheme(null)
  }

  const handleRemove = (uuid) => {
    dispatch(removeTheme(uuid))
  }

  return (
    <div className='list-stripes'>
      <div className='list-content'>
        {themes && themes.map(t =>
          <ListItem
            key={t.uuid}
            item={t}
            link={`/${projectUUID}/`}
            suffixLink='/dashboard'
            edit={() => handleEdit(t)}
            del={() => handleRemove(t.uuid)}
          />
        )}
        <ThemeInputDialog
          theme={theme}
          project={projectUUID}
          onCancel={handleCancel}
        />
      </div>
    </div>
  )
}

export default ThemeList
