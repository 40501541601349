import React from 'react'

const Page = ({ children }) => {
  return (
    <div>
      <div className='page'>
        {children}
      </div>
    </div>
  )
}

export default Page
