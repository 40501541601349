import React, { useState } from 'react'

import { ProjectInputDialog } from './ProjectInput'
import { useTranslation } from 'react-i18next'

const ProjectCreate = () => {
  const { t } = useTranslation()
  const [project, setProject] = useState(null)

  const create = () => {
    setProject({
      name: '',
      description: ''
    })
  }

  const cancel = () => {
    setProject(null)
  }

  return (
    <ProjectInputDialog project={project} onCancel={cancel}>
      <span onClick={create}>{t('projects.create.button')}</span>
    </ProjectInputDialog>
  )
}

export default ProjectCreate
