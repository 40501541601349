import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// import { compareStr } from '../../utils/methods'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

// import Select from '../Commons/Select'

import { selectAll as selectAllActors } from '../../redux/slices/actors'
import { selectAll as selectAllEvents } from '../../redux/slices/events'
import { selectAll as selectAllNarratives } from '../../redux/slices/narratives'

import {
  createLink,
  updateLink
} from '../../redux/slices/links'

const nodeTypes = [
  { value: 'Actor', label: 'commons.nodeTypes.actor' },
  { value: 'Narrative', label: 'commons.nodeTypes.narrative' },
  { value: 'Event', label: 'commons.nodeTypes.event' }
]

export const LinkInputDialog = ({
  link,
  theme,
  children,
  onCancel
}) => {
  const { t } = useTranslation()
  return (
    <div>
      {children}
      <Modal
        size='md'
        show={link}
        onHide={onCancel}
        backdrop='static'
      >
        <Modal.Header>
          {link && (
            link.uuid
              ? t('links.form.header.edit')
              : t('links.form.header.create')
          )}
        </Modal.Header>
        <Modal.Body>
          {link &&
            <LinkInput
              theme={theme}
              link={link}
              onCancel={onCancel}
            />}
        </Modal.Body>
      </Modal>
    </div>
  )
}

const LinkInput = ({
  link,
  theme,
  onCancel
}) => {
  const { t } = useTranslation()
  const [editedLink, setEditedLink] = useState(link)
  const dispatch = useDispatch()

  const nodes = {
    Actor: [],
    Event: [],
    Narrative: []
  }

  nodes.Actor = useSelector(selectAllActors)
  nodes.Event = useSelector(selectAllEvents)
  nodes.Narrative = useSelector(selectAllNarratives)

  const handleChangeLabel = (position, value) => {
    setEditedLink({
      ...editedLink,
      [position]: {
        label: value
      }
    })
  }

  const handleChangeNode = (position, value) => {
    setEditedLink({
      ...editedLink,
      [position]: {
        ...editedLink[position],
        uuid: value
      }
    })
  }

  const handleChangeName = (name) => {
    setEditedLink({ ...editedLink, name })
  }

  async function handleSubmit (e) {
    e.preventDefault()
    if (editedLink.uuid) {
      await dispatch(updateLink(editedLink))
    } else {
      await dispatch(createLink({ link: editedLink }))
    }
    onCancel()
  }

  return (
    <Form className='input-form' onSubmit={handleSubmit}>
      {/** source */}
      <div style={{ display: 'flex' }}>
        <Form.Group>
          <Form.Label>{t('links.form.input.sourceType')}</Form.Label>
          <Form.Select
            aria-label={t('links.form.input.sourceType')}
            value={editedLink && editedLink.source.label}
            onChange={(e) => handleChangeLabel('source', e.target.value)}
          >
            <option>{t('links.form.input.selectAType')}</option>
            {nodeTypes.map((nt, idx) => (
              <option key={idx} value={nt.value}>{t(nt.label)}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>{t('links.form.input.source')}</Form.Label>
          <Form.Select
            aria-label={t('links.form.input.source')}
            value={editedLink.source.uuid}
            onChange={(e) => handleChangeNode('source', e.target.value)}
          >
            <option>{t('links.form.input.selectASource')}</option>
            {editedLink.source.label && nodes[editedLink.source.label].map((n, idx) => (
              <option key={idx} value={n.uuid}>{n.name}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>

      {/** relation name */}
      <div style={{ display: 'flex' }}>
        <Form.Group>
          <Form.Label>{t('links.form.input.name')}</Form.Label>
          <Form.Control
            aria-label={t('links.form.input.name')}
            value={editedLink && editedLink.name}
            onChange={(e) => handleChangeName(e.target.value)}
          />
        </Form.Group>
      </div>

      {/** target */}
      <div style={{ display: 'flex' }}>
        <Form.Group>
          <Form.Label>{t('links.form.input.sourceType')}</Form.Label>
          <Form.Select
            aria-label={t('links.form.input.sourceType')}
            value={editedLink.target.label}
            onChange={(e) => handleChangeLabel('target', e.target.value)}
          >
            <option>{t('links.form.input.selectAType')}</option>
            {nodeTypes.map((nt, idx) => (
              <option key={idx} value={nt.value}>{t(nt.label)}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>{t('links.form.input.target')}</Form.Label>
          <Form.Select
            aria-label={t('links.form.input.target')}
            value={editedLink.target.uuid}
            onChange={(e) => handleChangeNode('target', e.target.value)}
          >
            <option>{t('links.form.input.selectATarget')}</option>
            {editedLink.target.label && nodes[editedLink.target.label].map((n, idx) => (
              <option key={idx} value={n.uuid}>{n.name}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>

      <div className='input-form-buttons'>
        <button className='warn' type='button' onClick={onCancel}>
          <i className='fa fa-close' /> {t('links.form.button.cancel')}
        </button>
        <button className='ok' type='submit' onClick={handleSubmit}>
          {link.persisted
            ? <div><i className='fa fa-save' /> {t('links.form.button.save')}</div>
            : <div><i className='fa fa-plus' /> {t('links.form.button.create')}</div>}
        </button>
      </div>
    </Form>
  )
}

LinkInput.propTypes = {
  link: PropTypes.object.isRequired
}

export default LinkInput
