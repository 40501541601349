.show_info_container {
  display: block;
}

.show_info_empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show_info_content {
  height: 100%;
  display: flex;
  padding: 24px 0;
  /* justify-content: center; */
  /* align-items: center; */
}

.show_info_image {
  width: 100%;
}

.show_info_header {
  padding: 1rem 0;
  text-transform: lowercase;
}

.show_info_neighbors ul {
  list-style-type: decimal-leading-zero;
  text-align: justify;
}

.show_info_hovered {
  font-weight: bold;
}

.side_pane_container {
  width: 100%;
  height: 100%;
}

.tooltip {
  position: absolute;
  text-align: center;
  padding: .5rem;
  background: #FFFFFF;
  color: #313639;
  border: 1px solid #313639;
  border-radius: 8px;
  pointer-events: none;
  font-size: 1.3rem;
  display: none;
  width: auto;
}


.Narrative circle {
  fill: #FF0F6E;
}

.Event circle {
  fill: #FF4100;
}

.Location circle {
  fill: #2D09D2;
}

/* Actors */
.not_human circle {
  fill: #2D09D2;
}

.human circle {
  fill: #2CDE15;
}