import React from 'react'
import Toast from 'react-bootstrap/Toast'

export const ToastWarning = ({ module, message, show, onClose }) => {
  return (
    <Toast show={show} onClose={onClose}>
      <Toast.Header>
        <strong className='me-auto'>{module}</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  )
}
