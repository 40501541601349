import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'

import joi from 'joi'

/**
 * api for acessing db
 */
import { narratives } from '../../lib/api'

/**
 * for d3 selectors
 */
import { scaleLinear } from 'd3-scale'
import { extent } from 'd3-array'

export const narrativeSchema = joi.object({
  name: joi.string().required(),
  description: joi.string().required(),
  source: joi.string()
})

export const narrativeHeader = ['name', 'description', 'source']

/**
 * adapters
 */
export const narrativesAdapter = createEntityAdapter({
  selectId: (i) => i.uuid
  // sortComparer: (a, b) => a.start.localeCompare(b.start)
})

/**
 * api calls and async functions
 */
export const loadNarrative = createAsyncThunk(
  'narratives/loadOne',
  (uuid) => narratives.get(uuid)
)

export const loadAllNarratives = createAsyncThunk(
  'narratives/loadAll',
  narratives.list
)

export const createNarrative = createAsyncThunk(
  'narratives/create',
  (obj) => narratives.create(obj)
)

export const uploadNarrativeData = createAsyncThunk(
  'narratives/uploadData',
  (obj) => narratives.uploadData(obj)
)

export const updateNarrative = createAsyncThunk(
  'narratives/update',
  async (obj) => {
    const { uuid, ...changes } = await narratives.update(obj)
    return { id: uuid, changes }
  }
)

export const removeNarrative = createAsyncThunk(
  'narratives/remove',
  (uuid) => narratives.remove(uuid)
)

/**
 * create slices
 */
export const narrativeSlices = createSlice({
  name: 'narratives',
  initialState: narrativesAdapter.getInitialState(),
  reducers: {
    clearAll: (state) => {
      state = narrativesAdapter.getInitialState()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadNarrative.fulfilled, narrativesAdapter.upsertOne)
    builder
      .addCase(loadAllNarratives.fulfilled, narrativesAdapter.setAll)
    builder
      .addCase(createNarrative.fulfilled, narrativesAdapter.addOne)
    builder
      .addCase(uploadNarrativeData.fulfilled, narrativesAdapter.addMany)
    builder
      .addCase(updateNarrative.fulfilled, narrativesAdapter.updateOne)
    builder
      .addCase(removeNarrative.fulfilled, narrativesAdapter.removeOne)
  }
})

/**
 * export selectors
 */
const selectNarratives = (state) => state.narratives
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectById
} = narrativesAdapter.getSelectors(selectNarratives)

export const selectNarrativesPosistions = ({ width, y }) =>
  createSelector(selectAll, (items) => {
    const degreeExtent = extent(items, n => n.degree || 1)
    const r = scaleLinear(degreeExtent, [6, 12])
    const x = scaleLinear([0, items.length], [r(degreeExtent[0]), width])

    return items.map((i, idx) => ({ ...i, x: x(idx), y, r: r(i.degree || 0.5) }))
  })

/**
 * export reducers
 */
export default narrativeSlices.reducer

/**
 * export actions
 */
export const { clearAll } = narrativeSlices.actions
