import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import DatePicker from 'react-datepicker'

import UploadImage from '../Commons/UploadImage'

import {
  createEvent,
  updateEvent
} from '../../redux/slices/events'

export const EventInputDialog = ({
  event,
  theme,
  children,
  onCancel
}) => {
  const { t } = useTranslation()
  return (
    <div>
      {children}
      <Modal
        size='md'
        show={event}
        onHide={onCancel}
        backdrop='static'
      >
        <Modal.Header>
          {event && event.uuid ? t('events.form.header.edit') : t('events.form.header.create')}
        </Modal.Header>
        <Modal.Body>
          {event &&
            <EventInput
              theme={theme}
              event={event}
              onCancel={onCancel}
            />}
        </Modal.Body>
      </Modal>
    </div>
  )
}

const EventInput = ({
  theme,
  event,
  onCancel
}) => {
  const { t } = useTranslation()
  const [editedEvent, setEditedEvent] = useState(event)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setEditedEvent(null)
    }
  }, [])

  async function handleSubmit (e) {
    e.preventDefault()
    if (editedEvent.uuid) {
      await dispatch(updateEvent(editedEvent))
    } else {
      await dispatch(createEvent({ event: editedEvent, theme }))
    }
    onCancel()
  }

  function handleChange (key, value) {
    setEditedEvent({ ...editedEvent, [key]: value })
  }

  return (
    <Form className='input-form' onSubmit={handleSubmit}>
      <Form.Group as={Row} controlId='eventName'>
        <Form.Label column>{t('events.form.input.name')}</Form.Label>
        <Col>
          <Form.Control
            type='text'
            placeholder={t('events.form.input.name')}
            value={editedEvent && editedEvent.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId='eventDescription'>
        <Form.Label column>{t('events.form.input.description')}</Form.Label>
        <Col>
          <Form.Control
            type='text'
            placeholder={t('events.form.input.description')}
            value={editedEvent && editedEvent.description}
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId='eventStart'>
        <Form.Label column>{t('events.form.input.start')}</Form.Label>
        <Col>
          <DatePicker
            selected={editedEvent.start ? Date.parse(editedEvent.start) : new Date()}
            dateFormat='dd/MM/yyyy'
            onChange={date => handleChange('start', date)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId='eventEnd'>
        <Form.Label column>{t('events.form.input.end')}</Form.Label>
        <Col>
          <DatePicker
            selected={editedEvent.end && Date.parse(editedEvent.end)}
            dateFormat='dd/MM/yyyy'
            onChange={date => handleChange('end', date)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId='eventImage'>
        <Form.Label column>{t('events.form.input.image')}</Form.Label>
        <Col>
          <UploadImage
            square
            minHeight='200px'
            image={editedEvent && editedEvent.image}
            onChange={(img) => handleChange('image', img)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col className='input-form-buttons'>
          <button className='warn' type='button' onClick={onCancel}>
            <i className='fa fa-close' /> {t('events.form.button.cancel')}
          </button>
          <button className='ok' type='submit' onClick={handleSubmit}>
            {event.uuid
              ? <div><i className='fa fa-save' /> {t('events.form.button.save')}</div>
              : <div><i className='fa fa-plus' /> {t('events.form.button.create')}</div>}
          </button>
        </Col>
      </Form.Group>
    </Form>
  )
}

export default EventInput
