import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Page from '../Commons/Page'
import LoginInput from '../Login/Login'
import About from '../About'

import {
  selectIsLogged
} from '../../redux/slices/auth'

const VerifyLogged = ({ children }) => {
  const isLogged = useSelector(selectIsLogged)
  return isLogged
    ? <Navigate replace to='/home' />
    : children
}

const FrontPage = () => (
  <VerifyLogged>
    <Page>
      <Container fluid>
        <Row className='frontpage'>
          <Col>
            <About />
          </Col>
          <Col>
            <LoginInput />
          </Col>
        </Row>
      </Container>
    </Page>
  </VerifyLogged>
)

export default FrontPage
