import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import moment from 'moment'

import { filterItems } from '../../lib/methods'

import { EventInputDialog } from './EventInput'
import Filter from '../Commons/Filter'
import UploadData from '../Commons/UploadData'

/**
 * redux actions and slices
 */

import {
  removeEvent,
  uploadEventData,
  eventHeader,
  eventSchema,
  selectAll as selectAllEvents
} from '../../redux/slices/events'

const EventList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState()
  const [selectedEvent, setSelectedEvent] = useState()

  const { themeUUID } = useParams('themeUUID')
  const events = useSelector(selectAllEvents)

  const handleChangeFilter = (f) => {
    setFilter(f)
  }
  const handleCreate = () => {
    setSelectedEvent({
      name: '',
      description: '',
      start: new Date()
    })
  }
  const handleEdit = (event) => {
    setSelectedEvent(event)
  }
  const handleRemove = (event) => {
    dispatch(removeEvent(event.uuid))
  }

  // TODO: add buttom to create new event
  return (
    <Container fluid>
      <div className='list-header'>
        <Filter filter={filter} onChange={handleChangeFilter} />
        <EventInputDialog
          event={selectedEvent}
          theme={themeUUID}
          onCancel={() => handleEdit(null)}
        >
          <Button onClick={handleCreate}>{t('events.list.mainHeader.button.create')}</Button>
        </EventInputDialog>
        <UploadData
          theme={themeUUID}
          header={eventHeader}
          schema={eventSchema}
          sendAction={uploadEventData}
          nodeType={t('events.list.nodeType')}
        />
      </div>
      <Table responsive striped bordered hover size='sm'>
        <thead>
          <tr>
            <td>{t('events.list.header.name')}</td>
            <td>{t('events.list.header.description')}</td>
            <td>{t('events.list.header.image')}</td>
            <td>{t('events.list.header.start')}</td>
            <td>{t('events.list.header.end')}</td>
            <td>{t('events.list.header.action')}</td>
          </tr>
        </thead>
        <tbody>
          {events.filter(filterItems(filter)).map(e =>
            <tr key={e.uuid}>
              <td>{e.name}</td>
              <td>{e.description}</td>
              <td>{e.image ? <img alt={e.name} src={e.image} height='100px' /> : '-'}</td>
              <td>{moment(e.start).format('DD MMM YYYY')}</td>
              <td>{e.end ? moment(e.end).format('DD MMM YYYY') : '-'}</td>
              <td
                className='list-item-actions'
              >
                <Button variant='secondary' onClick={() => handleEdit(e)}>
                  <i className='fa fa-pencil' />
                </Button>
                <Button variant='secondary' onClick={() => handleRemove(e)}>
                  <i className='fa fa-trash' />
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  )
}

EventList.propTypes = {
  themeUUID: PropTypes.string,
  theme: PropTypes.object
}

export default EventList
