import React, { useRef, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { select, event as d3event, drag as d3drag } from 'd3'
import {
  forceSimulation,
  forceLink,
  forceManyBody,
  forceCenter
} from 'd3-force'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'

import { nodeColors } from '../colors'
import { replaceImage, translateTypes } from '../../../lib/methods'
import {
  loadAllLinks,
  selectAllNeighbors
} from '../../../redux/slices/links'
import {
  loadNodesFromTheme,
  selectEntities as selectNodeEntities,
  selectIds as selectAllNodeIds
} from '../../../redux/slices/nodes'

import * as style from './Graph.module.css'

const margin = {
  left: 20,
  right: 20,
  top: 20,
  bottom: 20
}

const dim = {
  width: 600,
  height: 600
}

const data = {
  nodes: [],
  links: []
}

let currentNodeId = null
let nodeEntities = {}

let svg = null
let nodes = null
let links = null
let simulation = null
let started = false

let onSelect = null
let onMouseOver = null
let handleRedirect = null

let tooltip = null

function findColor (d) {
  if (nodeEntities[d.uuid]) {
    return nodeEntities[d.uuid].type
      ? nodeColors[d.label][nodeEntities[d.uuid].type]
      : nodeColors[d.label]
  }
}

function findImage (uuid) {
  if (nodeEntities[uuid] && nodeEntities[uuid].image) {
    return replaceImage(nodeEntities[uuid]).image
  } else {
    return null
  }
}

function drag (simulation) {
  function dragstarted (d) {
    if (!d3event.active) simulation.alphaTarget(0.3).restart()
    d3event.subject.fx = d3event.subject.x
    d3event.subject.fy = d3event.subject.y
  }

  function dragged (d) {
    d3event.subject.fx = d3event.x
    d3event.subject.fy = d3event.y
  }

  function dragended (event, d) {
    if (!d3event.active) simulation.alphaTarget(0)
    d3event.subject.fx = null
    d3event.subject.fy = null
  }

  return d3drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended)
}

function init (ref) {
  simulation = forceSimulation()
    .force('link', forceLink().id(d => d.uuid).distance(120))
    .force('charge', forceManyBody().strength(-240))
    .force('center', forceCenter(dim.width / 2, dim.height / 2))
    .on('end', ticked)

  svg = select(ref)
    .append('svg')
    .attr('width', dim.width)
    .attr('height', dim.height)
    .append('g')
    .style('transform', `translate(${margin.left}, ${margin.top})`)

  links = svg.selectAll('line')
    .data(data.links)
    .enter()
    .append('line')
    .style('stroke', '#69b3a2')

  nodes = svg.selectAll('circle')
    .data(data.nodes)
    .enter()
    .append('g')
    .call(drag(simulation))
    .on('click', function (d, i) {
      onSelect(nodeEntities[d.uuid])
    })
    .on('contextmenu', d => {
      d3event.preventDefault()
      handleRedirect(`../${d.uuid}`)
      onSelect(nodeEntities[d.uuid])
    })
    .on('mouseover', function (d, i) {
      onMouseOver(nodeEntities[d.uuid])
      select(this).transition()
        .duration('50')
        .attr('opacity', '.85')
      tooltip.html(nodeEntities[d.uuid].name)
        .style('left', (d3event.pageX + 10) + 'px')
        .style('top', (d3event.pageY - 15) + 'px')
      tooltip.transition()
        .duration(50)
        .style('display', 'block')
    })
    .on('mouseout', function (d, i) {
      onMouseOver(null)
      select(this).transition()
        .duration('50')
        .attr('opacity', '1')
      tooltip.transition()
        .duration(50)
        .style('display', 'none')
    })

  nodes
    .append('circle')
    .attr('r', 21)
    .style('fill', findColor)
  nodes
    .append('image')
    .attr('xlink:href', d => findImage(d.uuid))
    .attr('width', 30)
    .attr('height', 30)
    .attr('x', -15)
    .attr('y', -15)
}

function clear (ref) {
  select(ref)
    .selectAll('svg')
    .remove()
}

function ticked () {
  links
    .attr('x1', d => d.source.x)
    .attr('y1', d => d.source.y)
    .attr('x2', d => d.target.x)
    .attr('y2', d => d.target.y)

  nodes
    .attr('transform', d => `translate(${d.x},${d.y})`)
}

const ShowNeighbors = ({ nodes, hovered }) => {
  const navigate = useNavigate()
  return (
    <div className={style.show_info_neighbors}>
      <b>Ligado à:</b>
      <ul>
        {nodes.map(n =>
          <li key={n.uuid} className={hovered ? (hovered.uuid === n.uuid ? style.show_info_hovered : '') : ''}>
            <Link onClick={() => navigate(`../${n.uuid}`)}>{nodeEntities[n.uuid].name}</Link>
          </li>
        )}
      </ul>
    </div>
  )
}

const EventDuration = ({ node }) => (
  <span>
    <b>Duração: </b>
    {moment(node.start).format('DD/MM/YYYY')} - {' '}
    {node.end ? moment(node.end).format('DD/MM/YYYY') : '...'}
  </span>
)

const ShowInfo = ({ node, hovered }) => {
  if (node === null) {
    return (
      <div className={style.show_info_empty}>
        <Row>
          Selecione um nó do grafo
        </Row>
      </div>
    )
  }

  const neighbors = useSelector(state => selectAllNeighbors(state, node.uuid))
    .nodes
    .filter(n => n.uuid !== node.uuid)

  return (
    <div className={style.show_info_content}>
      <Col>
        {replaceImage(node).image
          ? (
            <Row>
              <div>
                <Image className={style.show_info_image} variant='top' src={replaceImage(node).image} rounded />
              </div>
            </Row>
            )
          : ''}
        <Row className={style.show_info_header}>
          <h5>{translateTypes(node)} - {node.name}</h5>
        </Row>
        <Row>
          <p>{node.description}</p>
          {node.label === 'Event' && <EventDuration node={node} />}
          {neighbors.length ? (<ShowNeighbors nodes={neighbors} hovered={hovered} />) : ''}
        </Row>
      </Col>
    </div>
  )
}

export const Graph = () => {
  const divRef = useRef(null)
  const divToolTipRef = useRef(null)
  const dispatch = useDispatch()
  const { themeUUID, nodeId } = useParams()
  const [selected, setSelected] = useState(null)
  const [hovered, setHovered] = useState(null)
  handleRedirect = useNavigate()

  const _data = useSelector(state => selectAllNeighbors(state, nodeId))

  nodeEntities = useSelector(selectNodeEntities)
  const nodeIds = useSelector(selectAllNodeIds)

  dim.width = window.innerWidth * (9 / 12) - 30
  dim.height = window.innerHeight - 65

  useEffect(() => {
    dispatch(loadAllLinks({ theme: themeUUID }))
    dispatch(loadNodesFromTheme(themeUUID))

    return () => {
      if (simulation) {
        clear(divRef.current)
        clear(divToolTipRef.current)
        simulation.stop()
        started = false
      }
    }
  }, [])

  useEffect(() => {
    if (simulation && nodeId !== currentNodeId) {
      clear(divRef.current)
      simulation.stop()
      started = false
    }
  }, [nodeId])

  useEffect(() => {
    if (divRef.current && nodeIds.length > 0 && started === false) {
      currentNodeId = nodeId
      data.nodes = JSON.parse(JSON.stringify(_data.nodes))
      data.links = JSON.parse(JSON.stringify(_data.links))

      if (data.nodes.length === 0) {
        data.nodes.push({
          uuid: nodeId,
          label: nodeEntities[nodeId].label
        })
      }

      init(divRef.current)

      simulation.nodes(data.nodes)
        .on('tick', ticked)

      simulation.force('link').links(data.links)

      started = true
    }
  }, [divRef, _data, nodeIds])

  useEffect(() => {
    if (divToolTipRef.current) {
      tooltip = select(divToolTipRef.current)
    }
  }, [divToolTipRef])

  onSelect = (node) => {
    setSelected(node)
  }

  onMouseOver = (node) => {
    setHovered(node)
  }

  return (
    <Container fluid>
      <Row>
        <Col md={3}>
          <Container className={style.side_pane_container}>
            <ShowInfo node={selected} hovered={hovered} />
          </Container>
        </Col>
        <Col md={9} ref={divRef} />
        <div className={style.tooltip} ref={divToolTipRef} />
      </Row>
    </Container>
  )
}
