import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Nav from 'react-bootstrap/Nav'
import { useTranslation } from 'react-i18next'

import { AuthenticatedPage } from '../Commons/Authenticated'

import { NavbarHeader } from '../Commons/Header'
import ProjectCreate from './ProjectCreate'
import ProjectList from './ProjectList'
import About from '../About'

import {
  loadAllProjects,
  selectAllProjects
} from '../../redux/slices/projects'

export const AllProjectsPage = () => {
  const { t } = useTranslation('description')
  const projects = useSelector(selectAllProjects)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadAllProjects())
  }, [])

  return (
    <AuthenticatedPage>
      <NavbarHeader title={t('title')}>
        <Nav>
          <div role='button' className='nav-link'>
            <ProjectCreate />
          </div>
        </Nav>
      </NavbarHeader>
      <About />
      <ProjectList projects={projects} />
    </AuthenticatedPage>
  )
}
