import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CompactPicker } from 'react-color'
import { useTranslation } from 'react-i18next'

import Modal from 'react-bootstrap/Modal'

import UploadImage from '../Commons/UploadImage'

import {
  createTheme,
  updateTheme
} from '../../redux/slices/themes'

export const ThemeInputDialog = ({
  theme,
  project,
  children,
  onCancel
}) => {
  const { t } = useTranslation()
  return (
    <div>
      {children}
      <Modal
        size='sm'
        show={theme}
        onHide={onCancel}
        backdrop='static'
      >
        <Modal.Header>
          {theme && (
            theme.uuid
              ? t('theme.header.form.edit')
              : t('theme.header.form.create')
          )}
        </Modal.Header>
        <Modal.Body>
          {theme &&
            <ThemeInput
              project={project}
              theme={theme}
              onCancel={onCancel}
            />}
        </Modal.Body>
      </Modal>
    </div>
  )
}

const ThemeInput = ({ theme, project, onCancel }) => {
  const { t } = useTranslation()
  const [editedTheme, seEditedTheme] = useState(theme)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      seEditedTheme(null)
    }
  }, [])

  async function onSubmit (e) {
    e.preventDefault()
    if (editedTheme.uuid) {
      await dispatch(updateTheme(editedTheme))
    } else {
      await dispatch(createTheme({ theme: editedTheme, project }))
    }
    onCancel()
  }

  function handleChange (key, value) {
    console.log(key, value)
    seEditedTheme({ ...editedTheme, [key]: value })
  }

  return (
    <form className='input-form' onSubmit={onSubmit}>
      {/** Theme name */}
      <input
        placeholder={t('themes.form.input.name')}
        value={editedTheme.name}
        onChange={e => handleChange('name', e.target.value)}
      />

      {/** Theme description */}
      <input
        placeholder={t('themes.form.input.description')}
        value={editedTheme.description}
        onChange={e => handleChange('description', e.target.value)}
      />

      {/** Theme color */}
      <CompactPicker
        color={editedTheme.color}
        onChangeComplete={color => handleChange('color', color.hex)}
      />

      {/** Theme image */}
      <UploadImage
        image={editedTheme.image}
        onChange={(img) => handleChange('image', img)}
      />

      {/** Theme buttons */}
      <div className='input-form-buttons'>
        <button className='warn' type='button' onClick={onCancel}>
          <i className='fa fa-close' /> {t('themes.form.button.cancel')}
        </button>
        <button className='ok' type='submit'>
          {editedTheme.uuid
            ? <div><i className='fa fa-save' /> {t('themes.form.button.save')}</div>
            : <div><i className='fa fa-plus' /> {t('themes.form.button.create')}</div>}
        </button>
      </div>
    </form>
  )
}

export default ThemeInput
