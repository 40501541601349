import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import UploadImage from '../Commons/UploadImage'

import {
  createActor,
  updateActor
} from '../../redux/slices/actors'

export const ActorInputDialog = ({
  actor,
  theme,
  children,
  onCancel
}) => {
  const { t } = useTranslation()
  return (
    <div>
      {children}
      <Modal
        size='md'
        show={actor}
        onHide={onCancel}
        backdrop='static'
      >
        <Modal.Header>
          {actor && (
            actor.uuid
              ? t('actors.form.header.edit')
              : t('actors.form.header.create')
          )}
        </Modal.Header>
        <Modal.Body>
          {actor &&
            <ActorInput
              theme={theme}
              actor={actor}
              onCancel={onCancel}
            />}
        </Modal.Body>
      </Modal>
    </div>
  )
}

const ActorInput = ({
  theme,
  actor,
  onCancel
}) => {
  const { t } = useTranslation()
  const [editedActor, setEditedActor] = useState(actor)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setEditedActor(null)
    }
  }, [])

  async function handleSubmit (e) {
    e.preventDefault()
    if (editedActor.uuid) {
      await dispatch(updateActor(editedActor))
    } else {
      await dispatch(createActor({ actor: editedActor, theme }))
    }
    onCancel()
  }

  function handleChange (key, value) {
    setEditedActor({ ...editedActor, [key]: value })
  }

  return (
    <Form className='input-form' onSubmit={handleSubmit}>
      <Form.Group controlId='actorName'>
        <Form.Label>{t('actors.form.input.name')}</Form.Label>
        <Form.Control
          type='text'
          placeholder={t('actors.form.input.name')}
          value={editedActor && editedActor.name}
          onChange={(e) => handleChange('name', e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId='actorDescription'>
        <Form.Label>{t('actors.form.input.description')}</Form.Label>
        <Form.Control
          as='textarea'
          placeholder={t('actors.form.input.description')}
          rows={4}
          value={editedActor && editedActor.description}
          onChange={(e) => handleChange('description', e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId='actorType'>
        <Form.Label>{t('actors.form.input.type')}</Form.Label>
        <Form.Select
          aria-label={t('actors.form.input.type')}
          value={editedActor.type}
          onChange={(e) => handleChange('type', e.target.value)}
        >
          <option>{t('actors.form.input.type.null')}</option>
          <option value='human'>{t('actors.form.input.type.human')}</option>
          <option value='not_human'>{t('actors.form.input.type.nonHuman')}</option>
        </Form.Select>
      </Form.Group>
      <Form.Group controlId='eventImage'>
        <Form.Label>{t('actors.form.input.image')}</Form.Label>
        <UploadImage
          square
          minHeight='150px'
          image={editedActor && editedActor.image}
          onChange={(img) => handleChange('image', img)}
        />
      </Form.Group>
      <Form.Group>
        <div className='input-form-buttons'>
          <button className='warn' type='button' onClick={onCancel}>
            <i className='fa fa-close' /> {t('actors.form.button.cancel')}
          </button>
          <button className='ok' type='submit' onClick={handleSubmit}>
            {actor.uuid
              ? <div><i className='fa fa-save' /> {t('actors.form.button.save')}</div>
              : <div><i className='fa fa-plus' /> {t('actors.form.button.create')}</div>}
          </button>
        </div>
      </Form.Group>
    </Form>
  )
}

export default ActorInput
