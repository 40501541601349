import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  selectIsAdmin
} from '../../redux/slices/auth'

const ListItem = ({
  item,
  link,
  suffixLink = '',
  small,
  noColor,
  children,
  edit,
  del
}) => {
  const [showIcons, setShowIcons] = useState()
  const isAdmin = useSelector(selectIsAdmin)
  const onHover = (value) => {
    setShowIcons(value)
  }

  return (
    <div
      className={`list-item ${small && 'small-item'} ${noColor && 'no-color-item'}`}
      style={{
        backgroundImage: item.image && `url(${item.image})`,
        backgroundColor: item.color && item.color
      }}
      onMouseOver={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <Link className='list-item-name' to={`${link}${item.uuid}${suffixLink}`}>
        {!children ? <div>{item.name}</div> : children}
      </Link>
      {
        showIcons && (edit || del) &&
          <div className='list-item-actions'>
            <div onClick={edit}><i className='fa fa-pencil' /></div>
            {isAdmin && <div onClick={del}><i className='fa fa-trash' /></div>}
          </div>
      }
    </div>
  )
}

export default ListItem
