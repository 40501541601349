/**
 * App.js
 * - Create the base app root with redux provider and router provider. These
 *   providers allows the developer to access and uses functions provided by
 *   the objects instanciated by this libraries
 */
import React, { Suspense } from 'react'
import { useSelector, useDispatch, Provider } from 'react-redux'

import { RouterProvider } from 'react-router-dom'
import ThemeProvider from 'react-bootstrap/ThemeProvider'

import { configureAppStore } from '../redux/store'

import Router from './Router'
import { ToastWarning } from './Commons/ToastWarning'

import {
  selectError,
  clearErrors
} from '../redux/slices/errors'

const HandleErrors = () => {
  const dispatch = useDispatch()
  const errors = useSelector(selectError)
  const handleCloseWarning = () => {
    dispatch(clearErrors())
  }
  return (
    <ToastWarning
      module={errors.action}
      message={errors.message}
      show={!!errors.message}
      onClose={handleCloseWarning}
    />
  )
}

const App = () => (
  <Suspense fallback='loading'>
    <Provider store={configureAppStore()}>
      <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint='xxs'
      >
        <RouterProvider router={Router} />
        <HandleErrors />
      </ThemeProvider>
    </Provider>
  </Suspense>
)

export default App
