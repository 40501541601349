import client from './client'

const route = '/login'

export default {
  login: (credentials) => client({
    url: route,
    method: 'post',
    data: credentials
  }).then(res => res.data)
}
