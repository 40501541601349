import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import ListItem from '../Commons/ListItem'
import { ProjectInputDialog } from './ProjectInput'

import {
  removeProject
} from '../../redux/slices/projects'

const ProjectList = ({ projects }) => {
  const [project, setProject] = useState()
  const dispatch = useDispatch()

  const handleEdit = (projectId) => {
    setProject(projectId)
  }

  const handleCancel = () => {
    setProject(null)
  }

  const handleRemove = (uuid) => {
    dispatch(removeProject(uuid))
  }
  return (
    <div className='list-stripes'>
      <div className='list-content'>
        {projects && projects.map(p =>
          <ListItem
            key={p.uuid}
            item={p}
            link='/'
            admin
            edit={() => handleEdit(p)}
            del={() => handleRemove(p.uuid)}
          />
        )}
      </div>
      <ProjectInputDialog project={project} onCancel={handleCancel} />
    </div>
  )
}

export default ProjectList
