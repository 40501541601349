import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useParams, useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Nav from 'react-bootstrap/Nav'

// page layout
import { AuthenticatedPage } from '../Commons/Authenticated'
import { NavbarHeader } from '../Commons/Header'

/**
 * redux actions and slices
 */
import { loadTheme, selectByIdTheme } from '../../redux/slices/themes'
import { loadAllLayers } from '../../redux/slices/layers'
import { loadAllLinks } from '../../redux/slices/links'
import { loadAllNarratives, clearAll as clearNarratives } from '../../redux/slices/narratives'
import { loadAllEvents, clearAll as clearEvents } from '../../redux/slices/events'
import { loadAllActors, clearAll as clearActors } from '../../redux/slices/actors'

const ThemePage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { projectUUID } = useParams('projectUUID')
  const { themeUUID } = useParams('themeUUID')

  const page = location.pathname.split('/').pop()

  const dispatch = useDispatch()
  const theme = useSelector((state) => selectByIdTheme(state, themeUUID))

  useEffect(() => {
    dispatch(loadTheme(themeUUID))
    dispatch(loadAllLayers({ theme: themeUUID }))
    dispatch(loadAllLinks({ theme: themeUUID }))
    dispatch(loadAllNarratives({ theme: themeUUID }))
    dispatch(loadAllEvents({ theme: themeUUID }))
    dispatch(loadAllActors({ theme: themeUUID }))
  }, [themeUUID])

  useEffect(() => {
    return () => {
      dispatch(clearNarratives())
      dispatch(clearEvents())
      dispatch(clearActors())
    }
  }, [])

  const { name, color, image } = theme || {}
  return (
    <div className='theme-page'>
      <NavbarHeader
        to={`/${projectUUID}`}
        title={name}
        color={color}
        image={image}
      >
        <Nav>
          <Nav.Link
            as={Link}
            to='dashboard'
            className={page === 'dashboard' ? 'visited' : ''}
          >
            <span>{t('themes.page.link.dashboard')}</span>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to='events'
            className={page === 'events' ? 'visited' : ''}
          >
            <span>{t('themes.page.link.events')}</span>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to='actors'
            className={page === 'actors' ? 'visited' : ''}
          >
            <span>{t('themes.page.link.actors')}</span>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to='narratives'
            className={page === 'narratives' ? 'visited' : ''}
          >
            <span>{t('themes.page.link.narratives')}</span>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to='links'
            className={page === 'links' ? 'visited' : ''}
          >
            <span>{t('themes.page.link.links')}</span>
          </Nav.Link>
        </Nav>
      </NavbarHeader>
      <Outlet />
    </div>
  )
}

export default () => (
  <AuthenticatedPage>
    <ThemePage />
  </AuthenticatedPage>
)
