
import { jsonReplacer } from '../methods'
import client from './client'
import { API_URL } from '../../constants'

const route = '/layers'

export default {
  getGeoJSON: async (url) => {
    const response = await client({
      url: `${API_URL}/${url}`,
      method: 'get',
      responseType: 'text'
    })

    return JSON.stringify(response.data)
  },
  get: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'get'
    })
    return response.data
  },
  list: async (filter) => {
    const qs = filter && Object.keys(filter).map(k => `${k}=${filter[k]}`).join('&')
    const response = await client({
      url: `${route}${qs && qs.length ? `?${qs}` : ''}`,
      method: 'get'
    })

    return response.data
  },
  create: async ({ geojsonfile, links, layer }) => {
    const form = new FormData()
    form.append('layer', JSON.stringify(layer, jsonReplacer))
    form.append('links', JSON.stringify(links, jsonReplacer))
    geojsonfile !== undefined && form.append('geojsonfile', geojsonfile)

    const response = await client({
      url: route,
      method: 'post',
      data: form
    })
    return response.data
  },
  update: async ({ geojsonfile, layer }) => {
    const form = new FormData()
    form.append('uuid', layer.uuid)
    form.append('layer', JSON.stringify(layer, jsonReplacer))
    geojsonfile !== undefined && form.append('geojsonfile', geojsonfile)

    const response = await client({
      url: route,
      method: 'put',
      data: form
    })
    return response.data
  },
  remove: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'delete'
    })
    return response.data
  }
}
