import moment from 'moment'
import client from './client'
import { API_URL } from '../../constants'

const route = '/nodes'

const formatDataFromDb = r => {
  const image = r.image ? `${API_URL}/${r.image}` : undefined
  const start = r.start ? r.start : undefined
  const end = r.start ? (r.end || (new Date()).toISOString()) : undefined
  return ({
    ...r,
    image,
    start,
    end
  })
}
export default {
  getNeighbors: async (uuid) => {
    try {
      const response = await client({
        url: `${route}/neighbors/${uuid}`,
        method: 'get'
      })
      return response.data
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },
  listFromTheme: async (theme) => {
    try {
      const response = await client({
        url: `${route}/theme/${theme}`,
        method: 'get'
      })
      return response.data
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },
  create: async ({ image, theme, node }) => {
    const form = new FormData()
    for (const key in node) {
      if (key === 'start' || key === 'end') {
        form.append(key, node[key].toISOString())
      } else {
        form.append(key, node[key])
      }
    }
    form.append('theme', theme)
    if (typeof image === 'object') {
      form.append('image', image)
    }

    try {
      const response = await client({
        url: route,
        method: 'post',
        data: form
      })
      return formatDataFromDb(response.data)
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  },
  uploadData: async ({ theme, data }) => {
    const node = data.map(d => ({
      ...d,
      start: d.start ? moment(d.start, 'DD/MM/YYYY').toISOString() : undefined,
      end: d.start ? (d.end ? moment(d.end, 'DD/MM/YYYY').toISOString() : null) : undefined
    }))
    try {
      const response = await client({
        url: `${route}/import`,
        method: 'post',
        data: { theme, data: node }
      })
      return response.data.map(formatDataFromDb)
    } catch (err) {
      console.error(err)
      return Promise.reject(err.response.data.message)
    }
  }
}
