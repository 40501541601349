import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

/**
 * map components
 */
import { GeoJSON } from 'react-leaflet'

/**
 * redux
 */
import {
  loadGeoJSON
} from '../../../redux/slices/layers'

const BaseGeoJSON = ({ layer }) => {
  /**
   * redux data
   */
  const dispatch = useDispatch()

  console.log(layer)

  /**
   * init component
   */
  useEffect(() => {
    // load when activated
    if (!layer.geojson) dispatch(loadGeoJSON(layer))
  }, [layer])

  const geoJSONStyle = (feature) => {
    return {
      color: (feature.properties && feature.properties.border) || '#1f2021',
      weight: 1,
      fillOpacity: (feature.properties && feature.properties.opacity) || 0.5,
      fillColor: (feature.properties && feature.properties.fill) || (layer && layer.fill) || '#fff2af'
    }
  }

  const onEachFeature = (f, l) => {
    // does this f have a property named popupContent
    if (f.properties) {
      l.bindPopup(Object.keys(f.properties)
        .filter(k => k !== 'geometry').map(key =>
          `<b>${key}:</b> <span>${f.properties[key]}</span>`
        ).join('<br/>'))
    }
  }

  return layer.geojson
    ? <GeoJSON data={layer.geojson ? JSON.parse(layer.geojson) : {}} style={geoJSONStyle} onEachFeature={onEachFeature} />
    : null
}

export default BaseGeoJSON
