export const TOKEN_KEY = process.env.TOKEN_KEY || '@indatlas'
export const NODE_ENV = process.env.NODE_ENV || 'dev'
export const APP_NAME = process.env.APP_NAME || 'indatlas'
export const APP_DESCRIPTION = process.env.APP_DESCRIPTION || 'indatlas'
export const API_URL = process.env.API_URL || 'http://localhost:5000'
export const BASE_URL = process.env.BASE_URL || ''

/** node types */
export const NODE_TYPES = {
  NARRATIVE: 'Narrative',
  ACTOR: 'Actor',
  EVENT: 'Event'
}
