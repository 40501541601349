import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import { csvToJson } from '../../lib/methods'

const AutoTable = ({ data, header }) => {
  const { t } = useTranslation()
  if (!data.length || header.length !== Object.keys(data[0]).length) return ''

  return (
    <div>
      <h6>{t('commons.uploadData.autoTable.title')}</h6>
      <Table responsive striped bordered hover size='sm'>
        <thead>
          <tr>
            {Object.keys(data[0]).map(k => (
              <td key={k}>{k}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(0, 2).map((d, i) => (
            <tr key={i}>
              {Object.keys(d).map(k => (
                <td key={k}>{d[k]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const UploadData = ({
  nodeType,
  header,
  schema,
  preprocessData,
  sendAction,
  theme
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])
  const [errors, setErrors] = useState({})

  const dispatch = useDispatch()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setData([])
    setOpen(false)
    setErrors({})
  }

  const handleChange = (e) => {
    const file = e.target.files[0]
    const reader = new window.FileReader()
    setData(file)

    reader.onloadend = () => {
      let content = csvToJson(reader.result, header)
      if (preprocessData) {
        content = content.map(preprocessData)
      }
      setErrors(content.reduce(
        (acc, cur, idx) => {
          const error = schema.validate(cur).error
          if (error) {
            return ({ ...acc, [idx]: error })
          }
          return acc
        },
        {}
      ))
      setData(content)
    }

    if (file) {
      reader.readAsText(file)
    } else {
      setData(null)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (data.length) {
      dispatch(sendAction({ data, theme }))
      handleClose()
    }
  }

  return (
    <div>
      <Button onClick={handleOpen}>{t('commons.uploadData.button')}</Button>
      <Modal
        size='md'
        show={open}
        onHide={handleClose}
        backdrop='static'
      >
        <Modal.Header>
          {t('commons.uploadData.header.title')} {nodeType}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' controlId='file'>
              <Form.Label>{t('commons.uploadData.body.chooseFile')}</Form.Label>
              <Form.Control type='file' onChange={handleChange} />
            </Form.Group>
            <AutoTable data={data} header={header} />
            {
              errors && Object.keys(errors)
                ? Object.keys(errors).map(e =>
                  <p key={e}>
                    <b>{t('commons.uploadData.body.error.line')} {+e + 2}</b> - {errors[e].message}<br />
                    {JSON.stringify(data[e])}
                  </p>
                )
                : ''
            }
            <Form.Group className='input-form-buttons'>
              <Button variant='none' onClick={handleClose}>
                <i className='fa fa-close' /> {t('commons.uploadData.body.button.cancel')}
              </Button>
              <Button variant='primary' type='submit' onClick={handleSubmit}>
                <i className='fa fa-save' /> {t('commons.uploadData.body.button.upload')}
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UploadData
