import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import { BASE_URL, NODE_ENV } from './constants'

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    debug: NODE_ENV === 'dev',
    ns: ['translation', 'description'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: BASE_URL + '/public/locales/{{lng}}/{{ns}}.json'
    }
  })
