import React, { useState } from 'react'

import { ThemeInputDialog } from './ThemeInput'
import { useTranslation } from 'react-i18next'

const ThemeCreate = ({ project }) => {
  const { t } = useTranslation()
  const [theme, setTheme] = useState()

  const handleCreate = () => {
    setTheme({
      name: '',
      description: ''
    })
  }

  const handleCancel = () => {
    setTheme()
  }

  return (
    <ThemeInputDialog theme={theme} project={project} onCancel={handleCancel}>
      <span onClick={handleCreate}>{t('themes.create.button')}</span>
    </ThemeInputDialog>
  )
}

export default ThemeCreate
