import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import {
  createNarrative,
  updateNarrative
} from '../../redux/slices/narratives'

export const NarrativeInputDialog = ({
  narrative,
  theme,
  children,
  onCancel
}) => {
  const { t } = useTranslation()
  return (
    <div>
      {children}
      <Modal
        size='md'
        show={narrative}
        onHide={onCancel}
        backdrop='static'
      >
        <Modal.Header>
          {narrative && (
            narrative.uuid
              ? t('narratives.form.header.edit')
              : t('narratives.form.header.create')
          )}
        </Modal.Header>
        <Modal.Body>
          {narrative &&
            <NarrativeInput
              theme={theme}
              narrative={narrative}
              onCancel={onCancel}
            />}
        </Modal.Body>
      </Modal>
    </div>
  )
}

const NarrativeInput = ({
  theme,
  narrative,
  onCancel
}) => {
  const { t } = useTranslation()
  const [editedNarrative, setEditedNarrative] = useState(narrative)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setEditedNarrative(null)
    }
  }, [])

  async function handleSubmit (e) {
    e.preventDefault()
    if (editedNarrative.uuid) {
      await dispatch(updateNarrative(editedNarrative))
    } else {
      await dispatch(createNarrative({ narrative: editedNarrative, theme }))
    }
    onCancel()
  }

  function handleChange (key, value) {
    setEditedNarrative({ ...editedNarrative, [key]: value })
  }

  return (
    <Form className='input-form' onSubmit={handleSubmit}>
      <Form.Group controlId='narrativeName'>
        <Form.Label>{t('narratives.form.input.name')}</Form.Label>
        <Form.Control
          type='text'
          placeholder={t('narratives.form.input.name')}
          value={editedNarrative && editedNarrative.name}
          onChange={(e) => handleChange('name', e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId='narrativeDescription'>
        <Form.Label>{t('narratives.form.input.description')}</Form.Label>
        <Form.Control
          as='textarea'
          placeholder={t('narratives.form.input.description')}
          rows={4}
          value={editedNarrative && editedNarrative.description}
          onChange={(e) => handleChange('description', e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId='narrativeSource'>
        <Form.Label>{t('narratives.form.input.source')}</Form.Label>
        <Form.Control
          type='text'
          placeholder={t('narratives.form.input.source')}
          value={editedNarrative && editedNarrative.source}
          onChange={(e) => handleChange('source', e.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <div className='input-form-buttons'>
          <button className='warn' type='button' onClick={onCancel}>
            <i className='fa fa-close' /> {t('narratives.form.button.cancel')}
          </button>
          <button className='ok' type='submit' onClick={handleSubmit}>
            {narrative.uuid
              ? <div><i className='fa fa-save' /> {t('narratives.form.button.save')}</div>
              : <div><i className='fa fa-plus' /> {t('narratives.form.button.create')}</div>}
          </button>
        </div>
      </Form.Group>
    </Form>
  )
}

export default NarrativeInput
