import client from './client'
import { API_URL } from '../../constants'

import moment from 'moment'

const route = '/events'

const formatDataFromDb = r => ({
  ...r,
  // start: moment(r.start).toDate(),
  end: r.end || (new Date()).toISOString(),
  image: r.image && `${API_URL}/${r.image}`
})

export default {
  get: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'get'
    })
    return formatDataFromDb(response.data)
  },
  list: async (filter) => {
    const qs = filter && Object.keys(filter).map(k => `${k}=${filter[k]}`).join('&')
    const response = await client({
      url: `${route}${qs && qs.length ? `?${qs}` : ''}`,
      method: 'get'
    })

    return response.data.map(formatDataFromDb)
  },
  create: async ({ image, theme, event }) => {
    const form = new FormData()
    for (const key in event) {
      if (key === 'start' || key === 'end') {
        form.append(key, event[key].toISOString())
      } else {
        form.append(key, event[key])
      }
    }
    form.append('theme', theme)
    if (typeof image === 'object') {
      form.append('image', image)
    }

    const response = await client({
      url: route,
      method: 'post',
      data: form
    })
    return formatDataFromDb(response.data)
  },
  uploadData: async ({ theme, data }) => {
    const events = data.map(e => ({
      ...e,
      start: moment(e.start, 'DD/MM/YYYY').toISOString(),
      end: e.end ? moment(e.end, 'DD/MM/YYYY').toISOString() : null
    }))
    try {
      const response = await client({
        url: `${route}/import`,
        method: 'post',
        data: { theme, data: events }
      })
      return response.data.map(formatDataFromDb)
    } catch (err) {
      return Promise.reject(err.response.data.message)
    }
  },
  update: async ({ image, ...event }) => {
    const form = new FormData()
    for (const key in event) {
      if (key === 'start' || key === 'end') {
        if (typeof event[key] !== 'string') {
          form.append(key, event[key].toISOString())
        } else {
          form.append(key, event[key])
        }
      } else if (key !== 'uuid') {
        form.append(key, event[key])
      }
    }
    if (typeof image === 'object') {
      form.append('image', image)
    }

    const response = await client({
      url: `${route}/${event.uuid}`,
      method: 'put',
      data: form
    })
    return formatDataFromDb(response.data)
  },
  remove: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'delete'
    })
    return response.data.uuid
  }
}
