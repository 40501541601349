import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import { filterItems } from '../../lib/methods'

import { NarrativeInputDialog } from './NarrativeInput'
import Filter from '../Commons/Filter'
import UploadData from '../Commons/UploadData'

/**
 * redux actions and slices
 */

import {
  removeNarrative,
  uploadNarrativeData,
  selectAll as selectAllNarratives,
  narrativeHeader,
  narrativeSchema
} from '../../redux/slices/narratives'

const NarrativeList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState()
  const [selectedNarrative, setSelectedNarrative] = useState()

  const { themeUUID } = useParams('themeUUID')

  const narratives = useSelector(selectAllNarratives)

  const handleChangeFilter = (f) => {
    setFilter(f)
  }
  const handleCreate = () => {
    setSelectedNarrative({
      name: '',
      description: '',
      source: ''
    })
  }
  const handleEdit = (narrative) => {
    setSelectedNarrative(narrative)
  }
  const handleRemove = (narrative) => {
    dispatch(removeNarrative(narrative.uuid))
  }

  return (
    <Container fluid>
      <div className='list-header'>
        <Filter filter={filter} onChange={handleChangeFilter} />
        <NarrativeInputDialog
          narrative={selectedNarrative}
          theme={themeUUID}
          onCancel={() => handleEdit(null)}
        >
          <Button onClick={handleCreate}>{t('narratives.list.mainHeader.button.create')}</Button>
        </NarrativeInputDialog>
        <UploadData
          theme={themeUUID}
          schema={narrativeSchema}
          header={narrativeHeader}
          sendAction={uploadNarrativeData}
          nodeType={t('narratives.list.nodeType')}
        />
      </div>
      <Table responsive striped bordered hover size='sm'>
        <thead>
          <tr>
            <td>{t('narratives.list.header.name')}</td>
            <td>{t('narratives.list.header.description')}</td>
            <td>{t('narratives.list.header.source')}</td>
            <td>{t('narratives.list.header.action')}</td>
          </tr>
        </thead>
        <tbody>
          {narratives.filter(filterItems(filter)).map(e =>
            <tr key={e.uuid}>
              <td>{e.name}</td>
              <td>{e.description}</td>
              <td>{e.source}</td>
              <td
                className='list-item-actions'
              >
                <Button variant='secondary' onClick={() => handleEdit(e)}>
                  <i className='fa fa-pencil' />
                </Button>
                <Button variant='secondary' onClick={() => handleRemove(e)}>
                  <i className='fa fa-trash' />
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  )
}

NarrativeList.propTypes = {
  themeUUID: PropTypes.string,
  theme: PropTypes.object
}

export default NarrativeList
