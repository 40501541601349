import { combineReducers } from 'redux'

// import all reducers
import auth from './slices/auth'
import projects from './slices/projects'
import themes from './slices/themes'
import links from './slices/links'
import events from './slices/events'
import actors from './slices/actors'
import narratives from './slices/narratives'
import layers from './slices/layers'
import nodes from './slices/nodes'
import errors from './slices/errors'

// combine into root reducer
const rootReducer = combineReducers({
  auth,
  projects,
  themes,
  links,
  actors,
  events,
  narratives,
  layers,
  nodes,
  errors
})

export default rootReducer
