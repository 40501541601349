import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'

import { nodes } from '../../lib/api'
import { NODE_TYPES } from '../../constants'

import moment from 'moment'

/**
 * for d3 selectors
 */
import { scaleTime, scaleLinear } from 'd3-scale'
import { extent } from 'd3-array'

/**
 * api calls and async functions
 */
export const loadNodeNeighbors = createAsyncThunk(
  'nodes/loadNeighbors',
  (uuid) => {
    return nodes.getNeighbors(uuid)
  }
)

export const loadNodesFromTheme = createAsyncThunk(
  'nodes/loadFromTheme',
  nodes.listFromTheme
)

export const createNode = createAsyncThunk(
  'nodes/create',
  (obj) => nodes.create(obj)
)

export const uploadData = createAsyncThunk(
  'nodes/uploadData',
  (obj) => nodes.uploadData(obj)
)

export const nodesAdapter = createEntityAdapter({
  selectId: (nodes) => nodes.uuid,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

export const nodeSlices = createSlice({
  name: 'nodes',
  initialState: nodesAdapter.getInitialState(),
  reducers: {
    clearAll: () => nodesAdapter.getInitialState()
  },
  extraReducers: (builder) => {
    builder.addCase(loadNodeNeighbors.fulfilled, nodesAdapter.setAll)
    builder.addCase(loadNodesFromTheme.fulfilled, nodesAdapter.setAll)
    builder.addCase(createNode.fulfilled, nodesAdapter.addOne)
    builder.addCase(uploadData.fulfilled, nodesAdapter.addMany)
  }
})

/**
 * export selectors
 */
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectById
} = nodesAdapter.getSelectors(state => state.nodes)

export const selectActors = createSelector(
  selectAll,
  items => items.filter(n => n.label === 'Actor')
)
export const selectActorsPosistions = ({ width, y }) =>
  createSelector(selectActors, (items) => {
    const degreeExtent = extent(items, n => n.degree || 1)
    const r = scaleLinear(degreeExtent, [6, 12])
    const x = scaleLinear([0, items.length], [r(degreeExtent[0]), width])

    return items.map((i, idx) => ({ ...i, x: x(idx), y, r: r(i.degree || 1) }))
  })

export const selectEvents = createSelector(
  selectAll,
  items => items.filter(n => n.label === 'Event')
)
export const selectEventsDates = createSelector(selectEvents,
  (events) => events.map(r => ({
    ...r,
    start: r.start ? moment(r.start).toDate() : r.start,
    end: r.end ? moment(r.end).toDate() : moment().toDate()
  })).sort((a, b) => a.start - b.start)
)

export const selectDateDomain = createSelector(selectEventsDates, (events) => {
  return events.reduce((acc, cur) => [
    (acc.length && acc[0] <= cur.start) ? acc[0] : cur.start,
    (acc.length && acc[1] >= cur.end) ? acc[1] : cur.end
  ], [])
})

export const selectEventsPosistions = ({ width, height }) =>
  createSelector(selectEventsDates, (items) => {
    const eventDomain = items.reduce((acc, cur) => [
      (acc.length && acc[0] <= cur.start) ? acc[0] : cur.start,
      (acc.length && acc[1] >= cur.end) ? acc[1] : cur.end
    ], [])

    const r = 4
    const eventLableHeight = 20
    const eventsHeight = height - eventLableHeight
    const x = scaleTime().domain(eventDomain).range([0, width])

    return items.map((i, idx) => ({
      ...i,
      x: x(i.start),
      y: eventsHeight - (idx * (r * 2)),
      r,
      width: Math.max(x(i.end) - x(i.start), 0),
      height: r * 2
    }))
  })

export const selectNarratives = createSelector(
  [selectAll],
  items => items.filter(n => n.label === NODE_TYPES.NARRATIVE)
)
export const selectNarrativesPosistions = ({ width, y }) =>
  createSelector(selectNarratives, (items) => {
    const degreeExtent = extent(items, n => n.degree || 1)
    const r = scaleLinear(degreeExtent, [6, 12])
    const x = scaleLinear([0, items.length], [r(degreeExtent[0]), width])

    return items.map((i, idx) => ({ ...i, x: x(idx), y, r: r(i.degree || 0.5) }))
  })

export const { clearAll } = nodeSlices.actions

/**
 * export reducers
 */
export default nodeSlices.reducer
