export const nodeColors = {
  Actor: { not_human: '#2D09D2', human: '#2CDE15' },
  ActorType: '#ffd166',
  Event: '#FF4100',
  Narrative: '#FF0F6E',
  Location: '#2D09D2'
}

export const nodeBackground = {
  Actor: '#073b4c',
  ActorType: '#118ab2',
  Event: '#666',
  Narrative: '#ffd166',
  Location: '#ef476f'
}

export const mainColors = {
  // body: '#F2F3ED',
  body: '#FAFAFA',
  boxColor: '#FFF'
}
