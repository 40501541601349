import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

import {
  isAuthenticated,
  getTokenInfo,
  getExp,
  getToken,
  setLogin,
  unsetToken
} from '../../lib/authentication'

import { auth } from '../../lib/api'

const initialState = () => {
  const info = getTokenInfo()
  return {
    loading: false,
    isAdmin: isAuthenticated() ? info.isAdmin : false,
    uuid: isAuthenticated() ? info.uuid : null,
    token: isAuthenticated() ? getToken() : null,
    exp: isAuthenticated() ? getExp() : null,
    error: null
  }
}

export const login = createAsyncThunk(
  'auth/login',
  (credentials) => auth.login(credentials)
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState(),
  reducers: {
    logout: (state) => {
      // update browser localStorage
      unsetToken()

      state = initialState()
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        setLogin(action.payload.token)
        const info = jwtDecode(action.payload.token)
        state.loading = false
        state.error = null
        state.uuid = info.uuid
        state.isAdmin = info.isAdmin
        state.token = action.payload.token
        state.exp = (info.iat + info.maxAgeSec) * 1000
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const selectAuth = (state) => state.auth
export const selectIsAdmin = (state) => state.auth.isAdmin
export const selectIsLogged = (state) =>
  state.auth.exp > (new Date()).getTime()

export default authSlice.reducer
export const { logout } = authSlice.actions
