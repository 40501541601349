import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { filterItems } from '../../lib/methods'

import { ActorInputDialog } from './ActorInput'
import ActorSelect from './ActorSelect'
import Filter from '../Commons/Filter'
import UploadData from '../Commons/UploadData'

/**
 * redux actions and slices
 */

import {
  removeActor,
  uploadActorData,
  selectAll as selectAllActors,
  actorHeader,
  actorSchema
} from '../../redux/slices/actors'

const preprocessUploadData = (d) => ({
  ...d,
  type: d.type.toLowerCase() === 'humano' ? 'human' : 'not_human'
})

const ActorList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState('')
  const [selectedActor, setSelectedActor] = useState()

  const { themeUUID } = useParams('themeUUID')

  const actors = useSelector(selectAllActors)

  const handleChangeFilter = (f) => {
    setFilter(f)
  }
  const handleCreate = () => {
    setSelectedActor({
      name: '',
      description: ''
    })
  }
  const handleEdit = (actor) => {
    if (actor) {
      const { uuid, name, description, type, image } = actor
      setSelectedActor({ uuid, name, description, type, image })
    } else {
      setSelectedActor(actor)
    }
  }
  const handleRemove = (actor) => {
    dispatch(removeActor(actor.uuid))
  }

  return (
    <Container fluid>
      <div className='list-header'>
        <Filter filter={filter} onChange={handleChangeFilter} />
        <ActorInputDialog
          actor={selectedActor}
          theme={themeUUID}
          onCancel={() => handleEdit(null)}
        >
          <Button onClick={handleCreate}>{t('actors.list.mainHeader.button.create')}</Button>
        </ActorInputDialog>
        <UploadData
          theme={themeUUID}
          header={actorHeader}
          schema={actorSchema}
          preprocessData={preprocessUploadData}
          sendAction={uploadActorData}
          nodeType={t('actors.list.nodeType')}
        />
      </div>

      <Row fluid='xl'>
        <Col xl='6'>
          <Table responsive striped bordered hover size='sm'>
            <thead>
              <tr>
                <td>{t('actors.list.header.name')}</td>
                <td>{t('actors.list.header.description')}</td>
                <td>{t('actors.list.header.image')}</td>
                <td>{t('actors.list.header.type')}</td>
                <td>{t('actors.list.header.location')}</td>
                <td>{t('actors.list.header.action')}</td>
              </tr>
            </thead>
            <tbody>
              {actors.filter(filterItems(filter)).map(e =>
                <tr key={e.uuid}>
                  <td>{e.name}</td>
                  <td>{e.description}</td>
                  <td>{e.image ? <img alt={e.name} src={e.image} height='100px' /> : '-'}</td>
                  <td>{e.type === 'human' ? t('actors.list.human') : t('actors.list.nonHuman')}</td>
                  <td>{e.geometry ? t('actors.list.geometry.yes') : t('actors.list.geometry.no')}</td>
                  <td
                    className='list-item-actions'
                  >
                    <Button variant='secondary' onClick={() => handleEdit(e)}>
                      <i className='fa fa-pencil' />
                    </Button>
                    <Button variant='secondary' onClick={() => handleRemove(e)}>
                      <i className='fa fa-trash' />
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col xl='6'>
          <ActorSelect actors={actors} />
        </Col>
      </Row>
    </Container>
  )
}

ActorList.propTypes = {
  themeUUID: PropTypes.string,
  theme: PropTypes.object
}

export default ActorList
