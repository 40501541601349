import jwtDecode from 'jwt-decode'

import { TOKEN_KEY } from '../constants'

export const getExp = () => {
  const info = getTokenInfo()
  return info && (info.exp || (info.iat + info.maxAgeSec)) * 1000
}

export const isAuthenticated = () => {
  return getExp() > (new Date()).getTime()
}
export const getToken = () => window.localStorage.getItem(TOKEN_KEY)
export const setLogin = token => {
  window.localStorage.setItem(TOKEN_KEY, token)
}
export const unsetToken = () => {
  window.localStorage.removeItem(TOKEN_KEY)
}
export const getTokenInfo = () => {
  const token = window.localStorage.getItem(TOKEN_KEY)
  return token && jwtDecode(token)
}
