import React from 'react'
import { useTranslation } from 'react-i18next'

const About = () => {
  const { t } = useTranslation(['description'])
  return (
    <div>
      <div className='about column'>
        <h2>{t('title')}</h2>
        <p>{t('about')}</p>
      </div>
    </div>
  )
}

export default About
