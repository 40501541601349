import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'

import { projects } from '../../lib/api'

/**
 * adapter
 */
export const projectsAdapter = createEntityAdapter({
  selectId: (project) => project.uuid,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

/**
 * api calls and async functions
 */
export const loadProject = createAsyncThunk(
  'projects/loadOne',
  (uuid) => projects.get(uuid)
)

export const loadAllProjects = createAsyncThunk(
  'projects/loadAll',
  () => projects.list()
)

export const createProject = createAsyncThunk(
  'projects/create',
  (obj) => projects.create(obj)
)

export const updateProject = createAsyncThunk(
  'projects/update',
  async (obj) => {
    const { uuid, ...changes } = await projects.update(obj)
    return { id: uuid, changes }
  }
)

export const removeProject = createAsyncThunk(
  'projects/remove',
  (uuid) => projects.remove(uuid)
)

// TODO: create & update project

const projectSlices = createSlice({
  name: 'projects',
  initialState: projectsAdapter.getInitialState(),
  reducers: {
    clearAll: (state) => {
      state = projectsAdapter.getInitialState()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadProject.fulfilled, projectsAdapter.upsertOne)
    builder
      .addCase(loadAllProjects.fulfilled, projectsAdapter.setAll)
    builder
      .addCase(createProject.fulfilled, projectsAdapter.addOne)
    builder
      .addCase(updateProject.fulfilled, projectsAdapter.updateOne)
    builder
      .addCase(removeProject.fulfilled, projectsAdapter.removeOne)
  }
})

// export const selectProjects =
export const {
  selectIds: selectProjectsIds,
  selectEntities: selectProjectsEntities,
  selectAll: selectAllProjects,
  selectById: selectByIdProject
} = projectsAdapter.getSelectors((state) => state.projects)

export default projectSlices.reducer
export const { clearAll: clearAllProjects } = projectSlices.actions
