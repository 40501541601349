import { createSlice } from '@reduxjs/toolkit'

function isPendingAction (action) {
  return action.type.endsWith('pending')
}
function isRejectedAction (action) {
  return action.type.endsWith('rejected')
}

const errorSlice = createSlice({
  name: 'error',
  initialState: {},
  reducers: {
    clear: (state) => {
      state = {}
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isPendingAction,
        (state, action) => {
          state = {}
          return state
        }
      )
    builder
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state = {
            ...action.error,
            action: action.type
          }
          return state
        }
      )
  }
})

export const selectError = (state) => state.errors

/**
 * export reducers
 */
export default errorSlice.reducer

/**
 * export actions
 */
export const { clear: clearErrors } = errorSlice.actions
