import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'

import { LinkInputDialog } from './LinkInput'

import { compareStr } from '../../lib/methods'

import UploadData from '../Commons/UploadData'

/**
 * redux actions and slices
 */
import {
  removeLink,
  uploadLinkData,
  selectAll as selectAllLinks,
  linkHeader,
  linkSchema
} from '../../redux/slices/links'

import { selectAll as selectAllActors } from '../../redux/slices/actors'
import { selectAll as selectAllEvents } from '../../redux/slices/events'
import { selectAll as selectAllNarratives } from '../../redux/slices/narratives'

const preprocessData = (d) => ({
  ...d,
  'source.label': (d['source.label'] || '').toLowerCase(),
  'target.label': (d['target.label'] || '').toLowerCase()
})

const LinkList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedLink, setSelectedLink] = useState()

  const { themeUUID } = useParams('themeUUID')

  const links = useSelector(selectAllLinks)

  const nodes = {
    Actor: [],
    Event: [],
    Narrative: []
  }

  nodes.Actor = useSelector(selectAllActors)
  nodes.Event = useSelector(selectAllEvents)
  nodes.Narrative = useSelector(selectAllNarratives)

  const handleCreate = () => {
    setSelectedLink({
      name: '',
      source: { type: '' },
      target: { type: '' }
    })
  }
  const handleEdit = (link) => {
    setSelectedLink(link)
  }
  const handleRemove = (link) => {
    dispatch(removeLink(link.uuid))
  }

  return (
    <Container fluid>
      <div className='list-header'>
        {/* <Filter filter={filter} onChange={handleChangeFilter} /> */}
        <LinkInputDialog
          link={selectedLink}
          theme={themeUUID}
          onCancel={() => handleEdit(null)}
        >
          <Button onClick={handleCreate}>{t('links.list.mainHeader.button.create')}</Button>
        </LinkInputDialog>
        <UploadData
          theme={themeUUID}
          header={linkHeader}
          schema={linkSchema}
          preprocessData={preprocessData}
          sendAction={uploadLinkData}
          nodeType={t('links.list.nodeType')}
        />
      </div>
      <Table responsive striped bordered hover size='sm'>
        <thead>
          <tr>
            <td>{t('links.list.header.type')}</td>
            <td>{t('links.list.header.source')}</td>
            <td>{t('links.list.header.type')}</td>
            <td>{t('links.list.header.target')}</td>
            <td>{t('links.list.header.name')}</td>
            <td>{t('links.list.header.action')}</td>
          </tr>
        </thead>
        <tbody>
          {links
            .sort((a, b) => compareStr(nodes[a.source.uuid], nodes[b.source.uuid]))
            .map(l => {
              const source = nodes[l.source.label]
                .find(n => n.uuid === l.source.uuid)
              const target = nodes[l.target.label]
                .find(n => n.uuid === l.target.uuid)
              return (
                <tr key={l.uuid}>
                  <td>{t(`commons.nodeTypes.${l.source.label.toLowerCase()}`)}</td>
                  <td>
                    {source ? source.name : t('links.list.commons.loading')}
                  </td>
                  <td>{t(`commons.nodeTypes.${l.target.label.toLowerCase()}`)}</td>
                  <td>
                    {target ? target.name : t('links.list.commons.loading')}
                  </td>
                  <td>{l.name}</td>
                  <td
                    className='list-item-actions'
                  >
                    <Button variant='secondary' onClick={() => handleRemove(l)}>
                      <i className='fa fa-trash' />
                    </Button>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </Table>
    </Container>
  )
}

LinkList.propTypes = {
  theme: PropTypes.object,
  themeUUID: PropTypes.string
}

export default LinkList
