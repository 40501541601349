import { replaceImage } from '../methods'
import client from './client'

const route = '/themes'

export default {
  get: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'get'
    })
    return replaceImage(response.data)
  },
  list: async (filters = {}) => {
    const strFilter = Object.keys(filters).map(key => `${key}=${filters[key]}`).join('&')
    const listRoute = route + (strFilter.length > 0 ? `?${strFilter}` : '')
    const response = await client({
      url: listRoute,
      method: 'get'
    })
    return response.data.map(p => replaceImage(p))
  },
  create: async ({ project, image, theme }) => {
    const form = new FormData()
    for (const key in theme) {
      form.append(key, theme[key])
    }
    form.append('project', project)
    if (typeof image === 'object') {
      form.append('image', image)
    }

    const response = await client({
      url: route,
      method: 'post',
      data: form
    })
    return replaceImage(response.data)
  },
  update: async ({ image, ...theme }) => {
    const form = new FormData()
    for (const key in theme) {
      if (key !== 'uuid') {
        form.append(key, theme[key])
      }
    }
    if (typeof image === 'object') {
      form.append('image', image)
    }

    const response = await client({
      url: `${route}/${theme.uuid}`,
      method: 'put',
      data: form
    })
    return replaceImage(response.data)
  },
  remove: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'delete'
    })
    return response.data.uuid
  }
}
