import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'

import moment from 'moment'
import joi from 'joi'
import joiDate from '@joi/date'

/**
 * api for acessing db
 */
import { events } from '../../lib/api'

/**
 * for d3 selectors
 */
import { scaleTime } from 'd3-scale'
// import { extent } from 'd3-array'

const Joi = joi.extend(joiDate)

export const eventSchema = joi.object({
  name: joi.string().required(),
  description: joi.string().required(),
  start: Joi.date().format('DD/MM/YYYY'),
  end: Joi.date().allow(null, '').format('DD/MM/YYYY')
})

export const eventHeader = ['name', 'description', 'start', 'end']

/**
 * adapters
 */
export const eventsAdapter = createEntityAdapter({
  selectId: (i) => i.uuid,
  sortComparer: (a, b) => a.start.localeCompare(b.start)
})

/**
 * api calls and async functions
 */
export const loadEvent = createAsyncThunk(
  'events/loadOne',
  (uuid) => events.get(uuid)
)

export const loadAllEvents = createAsyncThunk(
  'events/loadAll',
  events.list
)

export const createEvent = createAsyncThunk(
  'events/create',
  (obj) => events.create(obj)
)

export const uploadEventData = createAsyncThunk(
  'events/uploadData',
  (obj) => events.uploadData(obj)
)

export const updateEvent = createAsyncThunk(
  'events/update',
  async (obj) => {
    const { uuid, ...changes } = await events.update(obj)
    return { id: uuid, changes }
  }
)

export const removeEvent = createAsyncThunk(
  'events/remove',
  (uuid) => events.remove(uuid)
)

/**
 * create slices
 */
export const eventSlices = createSlice({
  name: 'events',
  initialState: eventsAdapter.getInitialState(),
  reducers: {
    clearAll: (state) => {
      state = eventsAdapter.getInitialState()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEvent.fulfilled, eventsAdapter.upsertOne)
    builder
      .addCase(loadAllEvents.fulfilled, eventsAdapter.setAll)
    builder
      .addCase(createEvent.fulfilled, eventsAdapter.addOne)
    builder
      .addCase(uploadEventData.fulfilled, eventsAdapter.addMany)
    builder
      .addCase(updateEvent.fulfilled, eventsAdapter.updateOne)
    builder
      .addCase(removeEvent.fulfilled, eventsAdapter.removeOne)
  }
})

/**
 * export selectors
 */
const selectEvents = (state) => state.events
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectById
} = eventsAdapter.getSelectors(selectEvents)

export const selectAllEventsDates = createSelector(
  [selectAll],
  (events) => events.map(r => ({
    ...r,
    start: r.start ? moment(r.start).toDate() : r.start,
    end: r.end ? moment(r.end).toDate() : r.end
  }))
)

export const selectDateDomain = createSelector(
  [selectAllEventsDates],
  (events) => {
    return events.reduce((acc, cur) => [
      (acc.length && acc[0] <= cur.start) ? acc[0] : cur.start,
      (acc.length && acc[1] >= cur.end) ? acc[1] : cur.end
    ], [])
  }
)

export const selectEventsPosistions = ({ width, height }) =>
  createSelector([selectAllEventsDates], (items) => {
    const eventDomain = items.reduce((acc, cur) => [
      (acc.length && acc[0] <= cur.start) ? acc[0] : cur.start,
      (acc.length && acc[1] >= cur.end) ? acc[1] : cur.end
    ], [])

    const r = 4
    const eventLableHeight = 20
    const eventsHeight = height - eventLableHeight
    const x = scaleTime().domain(eventDomain).range([0, width])

    return items.map((i, idx) => ({
      ...i,
      x: x(i.start),
      y: eventsHeight - (idx * (r * 2)),
      r,
      width: Math.max(x(i.end) - x(i.start), 0),
      height: r * 2
    }))
  })

/**
 * export reducers
 */
export default eventSlices.reducer

/**
 * export actions
 */
export const { clearAll } = eventSlices.actions
