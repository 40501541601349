import {
  createSlice,
  createSelector,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'

import joi from 'joi'

/**
 * api for acessing db
 */
import { actors } from '../../lib/api'

/**
 * for d3 selectors
 */
import { scaleLinear } from 'd3-scale'
import { extent } from 'd3-array'

export const actorSchema = joi.object({
  name: joi.string().required(),
  description: joi.string().required(),
  type: joi.string().allow('human', 'not_human')
})

export const actorHeader = ['name', 'description', 'type']

/**
 * adapters
 */
export const actorsAdapter = createEntityAdapter({
  selectId: (i) => i.uuid
  // sortComparer: (a, b) => a.start.localeCompare(b.start)
})

/**
 * api calls and async functions
 */
export const loadActor = createAsyncThunk(
  'actors/loadOne',
  (uuid) => actors.get(uuid)
)

export const loadAllActors = createAsyncThunk(
  'actors/loadAll',
  actors.list
)

export const createActor = createAsyncThunk(
  'actors/create',
  (obj) => actors.create(obj)
)

export const uploadActorData = createAsyncThunk(
  'actors/uploadData',
  (obj) => actors.uploadData(obj)
)

export const updateActor = createAsyncThunk(
  'actors/update',
  async (obj) => {
    const { uuid, ...changes } = await actors.update(obj)
    return { id: uuid, changes }
  }
)

export const updateActorBatch = createAsyncThunk(
  'actors/updateBatch',
  async (obj) => {
    const res = await actors.updateBatch(obj)
    return res.map(o => {
      const { uuid, ...changes } = o
      return ({ id: uuid, changes })
    })
  }
)

export const removeActor = createAsyncThunk(
  'actors/remove',
  (uuid) => actors.remove(uuid)
)

/**
 * create slices
 */
export const actorSlices = createSlice({
  name: 'actors',
  initialState: actorsAdapter.getInitialState(),
  reducers: {
    clearAll: (state) => {
      state = actorsAdapter.getInitialState()
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadActor.fulfilled, actorsAdapter.upsertOne)
    builder
      .addCase(loadAllActors.fulfilled, actorsAdapter.setAll)
    builder
      .addCase(createActor.fulfilled, actorsAdapter.addOne)
    builder
      .addCase(uploadActorData.fulfilled, actorsAdapter.addMany)
    builder
      .addCase(updateActor.fulfilled, actorsAdapter.updateOne)
    builder
      .addCase(updateActorBatch.fulfilled, actorsAdapter.updateMany)
    builder
      .addCase(removeActor.fulfilled, actorsAdapter.removeOne)
  }
})

/**
 * export selectors
 */
const selectActors = (state) => state.actors
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectById
} = actorsAdapter.getSelectors(selectActors)

export const selectActorsPosistions = ({ width, y }) =>
  createSelector(selectAll, (items) => {
    const degreeExtent = extent(items, n => n.degree || 1)
    const r = scaleLinear(degreeExtent, [6, 12])
    const x = scaleLinear([0, items.length], [r(degreeExtent[0]), width])

    return items.map((i, idx) => ({ ...i, x: x(idx), y, r: r(i.degree || 1) }))
  })

/**
 * export reducers
 */
export default actorSlices.reducer

/**
 * export actions
 */
export const { clearAll } = actorSlices.actions
