import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'

import { logout } from '../../redux/slices/auth'

export const NavbarHeader = ({ to, title, color, image, children }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const home = location.pathname === '/' || location.pathname === '/home'
  const style = {
    backgroundImage: image || '',
    backgroundColor: color
  }
  return (
    <Navbar className='header' style={style}>
      <Container fluid>
        <Navbar.Brand to={to} as={Link} className='logo'>
          <i className={`fa fa-${home ? 'home' : 'backward'}`} />
          {' '}
          {title}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse className='justify-content-end'>
          {children}
          <Link to='' onClick={() => dispatch(logout())}>{t('commons.header.logout')}</Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const Header = ({ to, title, color, image, tools }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch()
  const home = location.pathname === '/' || location.pathname === '/home'
  return (
    <div
      className='header'
      style={{ background: image ? `url(${image})` : color }}
    >
      <div className='title'>
        <div className='logo'>
          <Link to={to}>
            <i className={`fa fa-${home ? 'home' : 'backward'}`} />
          </Link>
          {' '}
          {title}
        </div>
      </div>
      {tools}
      <Link to='' onClick={() => dispatch(logout())}>{t('commons.header.logout')}</Link>
    </div>
  )
}

export default Header
