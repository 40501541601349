import { API_URL } from '../constants'

// export function assign (a, b) {
//   Object.keys(b).forEach(k => {
//     if (a.hasOwnProperty(k)) {
//       a[k] = b[k]
//     }
//   })
// }

export function translateTypes (node) {
  switch (node.label) {
    case 'Event':
      return 'Evento'
    case 'Narrative':
      return 'Narrativa'
    case 'Actor':
      if (node.type === 'non_human') return 'Ator Não Humano'
      return 'Ator Humano'
  }
}

export function strTruncate (str, length, ending) {
  if (length == null) {
    length = 100
  }
  if (ending == null) {
    ending = '...'
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

export function compareStr (a, b) {
  if (!a || !b) return 0
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
  return 0
}

export const filterItems = (filter) => (item) => {
  return item.name.toLowerCase().indexOf((filter || '').toLowerCase()) !== -1
}

export function jsonReplacer (key, value) {
  // Filtering out properties
  if (value === null) {
    return undefined
  }
  return value
}

export const replaceImage = (o) => ({
  ...o,
  image: o.image && `${API_URL}${o.image.replace('static','/uploads')}`
})

export const queryString = (o) => {
  const qs = new URLSearchParams()
  Object.keys(o).forEach(key => qs.append(key, o[key]))
  return qs
}

export const csvToJson = (csv, replaceHeaders = []) => {
  csv.replace(/“”/, '"')

  // Convert the data to String and
  // split it in an array
  const array = csv.split(/\r?\n|\r|\n/g)

  // All the rows of the CSV will be
  // converted to JSON objects which
  // will be added to result in an array
  const result = []

  // The array[0] contains all the
  // header columns so we store them
  // in headers array
  const headers = replaceHeaders.length ? replaceHeaders : array[0].split(',')

  // Since headers are separated, we
  // need to traverse remaining n-1 rows.
  for (let i = 1; i < array.length; i++) {
    const obj = {}

    // Create an empty object to later add
    // values of the current row to it
    // Declare string str as current array
    // value to change the delimiter and
    // store the generated string in a new
    // string s
    const str = array[i]
    let s = ''

    // By Default, we get the comma separated
    // values of a cell in quotes " " so we
    // use flag to keep track of quotes and
    // split the string accordingly
    // If we encounter opening quote (")
    // then we keep commas as it is otherwise
    // we replace them with pipe |
    // We keep adding the characters we
    // traverse to a String s
    let flag = 0
    for (let ch of str) {
      if (ch === '"' && flag === 0) {
        flag = 1
      } else if (ch === '"' && flag === 1) {
        flag = 0
      }
      if (ch === ',' && flag === 0) ch = '|'
      if (ch !== '"') s += ch
    }

    // Split the string using pipe delimiter |
    // and store the values in a properties array
    const properties = s.split('|')

    // For each header, if the value contains
    // multiple comma separated data, then we
    // store it in the form of array otherwise
    // directly the value is stored
    for (const j in headers) {
      // if (properties[j].includes(', ')) {
      //   obj[headers[j]] = properties[j]
      //     .split(', ').map(item => item.trim())
      // } else {
      //   obj[headers[j]] = properties[j]
      // }
      obj[headers[j]] = properties[j]
    }

    // Add the generated object to our
    // result array
    result.push(obj)
  }

  return result
}
