import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit'

import { themes } from '../../lib/api'

/**
 * adapter
 */
export const themesAdapter = createEntityAdapter({
  selectId: (theme) => theme.uuid,
  sortComparer: (a, b) => a.name.localeCompare(b.name)
})

/**
 * api calls and async functions
 */
export const loadTheme = createAsyncThunk(
  'themes/loadOne',
  (uuid) => themes.get(uuid)
)

export const loadAllThemes = createAsyncThunk(
  'themes/loadAll',
  (filter) => themes.list(filter)
)

export const createTheme = createAsyncThunk(
  'themes/create',
  (obj) => themes.create(obj)
)

export const updateTheme = createAsyncThunk(
  'themes/update',
  async (obj) => {
    const { uuid, ...changes } = await themes.update(obj)
    return { id: uuid, changes }
  }
)

export const removeTheme = createAsyncThunk(
  'themes/remove',
  (uuid) => themes.remove(uuid)
)

// TODO: create & update

/**
 * create slices
 */
const themeSlices = createSlice({
  name: 'themes',
  initialState: themesAdapter.getInitialState(),
  reducers: {
    clearAll: () => themesAdapter.getInitialState()
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTheme.fulfilled, themesAdapter.upsertOne)
    builder
      .addCase(loadAllThemes.fulfilled, themesAdapter.setAll)
    builder
      .addCase(createTheme.fulfilled, themesAdapter.addOne)
    builder
      .addCase(updateTheme.fulfilled, themesAdapter.updateOne)
    builder
      .addCase(removeTheme.fulfilled, themesAdapter.removeOne)
  }
})

/**
 * export selectors
 */
export const {
  selectIds: selectThemesIds,
  selectEntities: selectThemesEntities,
  selectAll: selectAllThemes,
  selectById: selectByIdTheme
} = themesAdapter.getSelectors((state) => state.themes)

/**
 * export reducers
 */
export default themeSlices.reducer

/**
 * export actions
 */
export const { clearAll: clearAllThemes } = themeSlices.actions
