// import FormData from 'form-data'

import client from './client'
import { replaceImage } from '../methods'

const route = '/actors'

export default {
  get: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'get'
    })
    return replaceImage(response.data)
  },
  list: async (filter) => {
    const qs = filter && Object.keys(filter).map(k => `${k}=${filter[k]}`).join('&')
    const response = await client({
      url: `${route}${qs && qs.length ? `?${qs}` : ''}`,
      method: 'get'
    })

    return response.data.map(replaceImage)
  },
  create: async ({ image, theme, actor }) => {
    const form = new FormData()
    for (const key in actor) {
      form.append(key, actor[key])
    }
    form.append('theme', theme)
    if (typeof image === 'object') {
      form.append('image', image)
    }

    const response = await client({
      url: route,
      method: 'post',
      data: form
    })
    return replaceImage(response.data)
  },
  uploadData: async (data) => {
    try {
      const response = await client({
        url: `${route}/import`,
        method: 'post',
        data
      })
      return response.data
    } catch (err) {
      return Promise.reject(err.response.data.message)
    }
  },
  update: async ({ image, ...actor }) => {
    const form = new FormData()
    for (const key in actor) {
      if (key !== 'uuid') {
        form.append(key, actor[key])
      }
    }

    if (typeof image === 'object') {
      form.append('image', image)
    }

    const response = await client({
      url: `${route}/${actor.uuid}`,
      method: 'put',
      data: form
    })
    return replaceImage(response.data)
  },
  updateBatch: async (actors) => {
    try {
      const response = await client({
        url: `${route}`,
        method: 'put',
        data: actors
      })
      return response.data.map(replaceImage)
    } catch (err) {
      return err
    }
  },
  remove: async (uuid) => {
    const response = await client({
      url: `${route}/${uuid}`,
      method: 'delete'
    })
    return response.data.uuid
  }
}
