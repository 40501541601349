/**
 * ProjectPage.js:
 * Defines the structure of a project page with its description and themes lists
 */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'

import { NavbarHeader } from '../Commons/Header'
import { AuthenticatedPage } from '../Commons/Authenticated'

import ThemeCreate from '../Themes/ThemeCreate'
import ThemeList from '../Themes/ThemeList'

import {
  loadProject,
  selectByIdProject
} from '../../redux/slices/projects'

import {
  clearAllThemes,
  loadAllThemes,
  selectAllThemes
} from '../../redux/slices/themes'

const ProjectPageComponent = () => {
  const { projectUUID } = useParams('projectUUID')
  const dispatch = useDispatch()

  const project = useSelector((state) => selectByIdProject(state, projectUUID))
  const themes = useSelector(selectAllThemes)

  useEffect(() => {
    dispatch(loadProject(projectUUID))
    dispatch(loadAllThemes({ project: projectUUID }))

    return () => {
      dispatch(clearAllThemes())
    }
  }, [])

  return (
    <div className='project-page'>
      <NavbarHeader
        to='/'
        title={project ? project.name : ''}
        color={project ? project.color : ''}
        image={project ? project.image : ''}
      >
        <Nav>
          <div role='button' className='nav-link'>
            <ThemeCreate project={projectUUID} />
          </div>
        </Nav>
      </NavbarHeader>
      <div className='about'>
        <p>{project && project.description}</p>
      </div>
      <ThemeList themes={themes} />
    </div>
  )
}

export default () => (
  <AuthenticatedPage>
    <ProjectPageComponent />
  </AuthenticatedPage>
)
