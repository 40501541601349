import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'

import rootReducer from './rootReducer'

export function configureAppStore () {
  const isDev = process.env.NODE_ENV !== 'production'
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      isDev
        ? getDefaultMiddleware().concat(logger)
        : getDefaultMiddleware()
  })
  return store
}
