import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { select } from 'd3'

import { useSelector, useDispatch } from 'react-redux'

import {
  selectAll,
  selectEvents,
  loadNodesFromTheme
} from '../../../redux/slices/nodes'

export const Association = () => {
  const ref = useRef()
  const dispatch = useDispatch()
  const { themeUUID } = useParams('themeUUID')

  const events = useSelector(selectEvents)

  // set the dimensions and margins of the graph
  const margin = { top: 30, right: 30, bottom: 70, left: 60 }
  const width = 460 - margin.left - margin.right
  const height = 400 - margin.top - margin.bottom

  const data = []

  console.log(events)
  useEffect(() => {
    if (themeUUID) {
      dispatch(loadNodesFromTheme(themeUUID))
    }
  }, [themeUUID])

  useEffect(() => {
    // append the svg object to the body of the page
    const svg = select(ref.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)
  }, [data])

  return <div className='association' ref={ref} />
}
