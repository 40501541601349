import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

/**
 * d3 funcions
 */
import { scaleTime } from 'd3-scale'
import { timeYear } from 'd3-time'

/**
 * nodes style
 */
import { nodeColors } from '../colors'

/**
 * redux slices
 */

import {
  selectEventsPosistions,
  selectDateDomain
} from '../../../redux/slices/events'

import {
  selectActorsPosistions
} from '../../../redux/slices/actors'

import {
  selectNarrativesPosistions
} from '../../../redux/slices/narratives'

import {
  selectAll as selectAllLinks
} from '../../../redux/slices/links'

/**
 * styles
 */
import './time_graph.less'

const Tick = ({ label, x, y, height }) => (
  <g
    className='tick'
    transform={`translate(${x},${y})`}
  >
    <text x={4} fill='#666'>{label}</text>
    <line y2={height} stroke='#DADADA' />
  </g>
)

const Events = ({ width, height, margin }) => {
  const navigate = useNavigate()
  const [showName, setShowName] = useState()
  const events = useSelector(selectEventsPosistions({ width, height, margin }))
  const eventDomain = useSelector(selectDateDomain)

  const barSize = -15
  const x = eventDomain && scaleTime().domain(eventDomain).range([margin, width - margin])
  const timespan = eventDomain.length
    ? (eventDomain[1].getFullYear() - eventDomain[0].getFullYear() >= 10 ? 5 : 1)
    : 1

  const toggleShowName = (uuid) => {
    setShowName(uuid)
  }

  return (
    <g className='event-nodes' transform={`translate(${margin},${0})`}>
      {events.map((e, idx) => (
        <g
          key={idx}
          className='event'
          transform={`translate(${e.x},${e.y})`}
          onMouseEnter={() => toggleShowName(e.uuid)}
          onMouseLeave={() => toggleShowName(null)}
        >
          {showName === e.uuid ? <text transform='rotate(-90)'>{e.name}</text> : ''}
          <rect
            x={-e.r}
            y={-e.r}
            width={e.width}
            height={2 * e.r}
            fill={nodeColors.Event} opacity={0.7}
            onClick={() => navigate(`../${e.uuid}`)}
          />
        </g>
      ))}
      {x.ticks(timeYear.every(timespan)).map((start, idx) =>
        <Tick key={idx} x={x(start)} y={height} height={barSize} label={start.getFullYear()} />
      )}
      {x.ticks(timeYear.every(timespan)).map((end, idx) =>
        <Tick key={idx} x={x(end)} y={height} height={barSize} label={end.getFullYear()} />
      )}
    </g>
  )
}

const Actors = ({ width, height, margin, y }) => {
  const navigate = useNavigate()
  const [showName, setShowName] = useState()
  const actors = useSelector(selectActorsPosistions({ width, y }))

  const toggleShowName = (uuid) => {
    setShowName(uuid)
  }

  return (
    <g className='actor-nodes' transform={`translate(${margin},${0})`}>
      {actors.map((a, idx) => (
        <g
          key={idx}
          className='actor'
          transform={`translate(${a.x},${a.y})`}
          onMouseEnter={() => toggleShowName(a.uuid)}
          onMouseLeave={() => toggleShowName(null)}
        >
          {showName === a.uuid ? <text x={-15} transform='rotate(-90)'>{a.name}</text> : ''}
          <circle
            r={a.r}
            fill={nodeColors.Actor[a.type]}
            opacity={0.7}
            onClick={() => navigate(`../${a.uuid}`)}
          />
        </g>
      ))}
    </g>
  )
}

const Narratives = ({ width, margin, y }) => {
  const navigate = useNavigate()
  const [showName, setShowName] = useState()
  const narratives = useSelector(selectNarrativesPosistions({ width, y }))

  const toggleShowName = (uuid) => {
    setShowName(uuid)
  }

  return (
    <g className='narrative-nodes' transform={`translate(${margin},${0})`}>
      {narratives.map((n, idx) => (
        <g
          key={idx}
          className='narrative'
          transform={`translate(${n.x},${n.y})`}
          onMouseEnter={() => toggleShowName(n.uuid)}
          onMouseLeave={() => toggleShowName(null)}
        >
          {showName === n.uuid ? <text y={-15} transform='rotate(-90)'>{n.name.slice(0, 64)}</text> : ''}
          <circle
            r={n.r}
            fill={nodeColors.Narrative}
            opacity={0.7}
            onClick={() => navigate(`../${n.uuid}`)}
          />
        </g>
      ))}
    </g>
  )
}

const Edges = ({ width, height, margin, yActors, yNarratives }) => {
  const links = useSelector(selectAllLinks)
  const events = useSelector(selectEventsPosistions({ width, height, margin }))
  const actors = useSelector(selectActorsPosistions({ width, y: yActors }))
  const narratives = useSelector(selectNarrativesPosistions({ width, y: yNarratives }))

  const getNode = (uuid) =>
    events.find(i => i.uuid === uuid) ||
      actors.find(i => i.uuid === uuid) ||
        narratives.find(i => i.uuid === uuid)

  return (
    <g className='links' transform={`translate(${margin},${0})`}>
      {links.map((l, idx) => {
        const s = getNode(l.source.uuid)
        const t = getNode(l.target.uuid)

        if (!s || !t) return null
        return (
          <line
            key={idx}
            x1={s.x}
            y1={s.y}
            x2={t.x}
            y2={t.y}
            strokeWidth={2}
            stroke='#666'
            opacity={0.5}
          />
        )
      })}
    </g>
  )
}

const TimeGraph = ({ width, height }) => {
  const ref = useRef()
  /**
   * visualization variables
   */
  const margin = 20
  const yActors = margin
  const yNarratives = (height - (2 * margin)) / 2
  const w = (ref && ref.current) ? ref.current.parentElement.clientWidth : width

  // console.log(ref.current)

  /**
   * render
   */
  return (
    <svg
      ref={ref}
      height={height}
      style={{
        width: '100%',
        height
      }}
    >
      <Actors
        width={w - (2 * margin)}
        height={height - (2 * margin)}
        margin={margin} y={yActors}
      />
      <Narratives
        width={w - (2 * margin)}
        height={height - (2 * margin)} margin={margin} y={yNarratives}
      />
      <Events width={w - (2 * margin)} height={height - (2 * margin)} margin={margin} />
      <Edges
        width={w - (2 * margin)}
        height={height - (2 * margin)}
        margin={margin}
        yActors={yActors}
        yNarratives={yNarratives}
      />
    </svg>
  )
}

export default TimeGraph
