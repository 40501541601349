/**
 * Index file contains:
 *  - all imports of css and adaptations for UI libs to work properly
 *  - find the root element of index to mount the react application
 */

import React from 'react'
import { render } from 'react-dom'

import moment from 'moment'

import 'regenerator-runtime/runtime'

// load css for antd UI
import 'rc-tooltip/assets/bootstrap_white.css'
import 'rc-calendar/assets/index.css'
import 'rc-dialog/assets/index.css'
import 'antd/dist/antd.css'

// react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import './style.less'

// load css for leaflet UI
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import 'leaflet-draw'
import 'leaflet-draw/dist/images/spritesheet.svg'
import 'leaflet-draw/dist/leaflet.draw.css'

import App from './components/App'

import './i18n'

// fix leaflet icons path to show correctly in UI
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

// Set moment locale for dates like months and days of week in portuguese
moment.locale('pt')

// Append the application on the correct div element on index.html
render(
  <App />,
  document.getElementById('app')
)
