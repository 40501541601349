import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import Page from './Page'

import {
  selectIsLogged,
  selectIsAdmin
} from '../../redux/slices/auth'

export const Authenticated = ({ children }) => {
  const isLogged = useSelector(selectIsLogged)
  return !isLogged
    ? <Navigate replace to='/' />
    : children
}

export const AdminSecure = ({ children }) => {
  const isLogged = useSelector(selectIsLogged)
  const isAdmin = useSelector(selectIsAdmin)
  if (!isLogged) return <Navigate replace to='/' />
  if (!isAdmin) return <Navigate replace to='/home' />
  return children
}

export const AuthenticatedPage = ({ children }) => (
  <Authenticated>
    <Page>
      {children}
    </Page>
  </Authenticated>
)

export const AdminPage = ({ children }) => (
  <AdminSecure>
    <Page>
      {children}
    </Page>
  </AdminSecure>
)
